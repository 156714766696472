import defaultConfig from './default.json'
import productionConfig from './production.json'
import versionFile from './version.json'

const config = {
  ...defaultConfig,
  ...(process.env.NODE_ENV !== 'development' && productionConfig),
  ...versionFile,
}

export default config
