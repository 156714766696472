import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import DownloadDocumentButton from 'components/system_wide/download_document_button'
import { error } from 'components/system_wide/notification'
import config from 'config'
import withJWTSession from 'enhancers/jwt_session'
import i18next from 'i18next'
import axios from 'lib/axios'
import jsonapiToData from 'lib/jsonapi_to_data'
import { isAdmin, isCondominiaAdmin, isSubaccount } from 'lib/utils'
import validateFields from 'lib/validators/mailing'
import hash from 'object-hash'
import React from 'react'
import { Button, Form, Table } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getSingleCondominium } from 'store/condominia'
import {
  createAttachment,
  createMailer,
  deleteAttachment,
  deleteMailer,
  getCommunications,
  getMailer,
  getMailerAttachments,
  getPrintOptions,
  getRecipients,
  resetCurrentMailer,
  updateErrors,
  updateMailer,
} from 'store/mailing'
import AddAttachment from './add_attachment'
import Breadcrumbs from './components/breadcrumbs'
import CostCenterSelector from './components/cost_center_selector'
import MailParentSelector from './components/mailer_parent_selector'

const { t } = i18next

const mstp = state => {
  let { condominia, destinations, communication_types, errors, print_options, current_mailer } = state.mailing
  let { role_id, id: my_user_id, name, surname } = state.userInfo
  let { strings: trans } = state.translations
  let { roles } = state.usersData
  return {
    destinations,
    communication_types,
    isAdmin: isAdmin(role_id, roles),
    isCondominiaAdmin: isCondominiaAdmin(role_id, roles),
    isSubaccount: isSubaccount(role_id, roles),
    my_user_id,
    errors,
    print_options,
    trans,
    current_mailer,
    condominia,
    roles,
    name,
    surname,
  }
}

class NewMailing extends React.Component {
  static defaultProps = {
    destinations: [],
    errors: {},
    print_options: {},
    current_mailer: {},
  }
  initialState = {
    mail_sender: null,
    typology: null,
    selected_communication_type: null,
    document: null,
    selectedFile: '',
    selectedFileLabel: '',
    validated: false,
    title: '',
    files: [],
    selected_options: {},
    usersOpts: [],
    condominiaOpts: [],
    payer_id: '',
    payer_type: '',
    virtual_attachment: false,
    user_id: '',
    parent_id: '',
    sdi: '',
    iban: '',
  }

  state = { ...this.initialState }

  uploadForm = null
  initialHash = null

  async componentDidMount() {
    if (this.props.router.params.mailer_id !== '0') {
      await this.props.dispatch(getMailer(this.props.router.params.mailer_id))
      // await this.props.dispatch(getMailerAttachments(this.props.router.params.mailer_id))
      await this.props.dispatch(getRecipients(this.props.router.params.mailer_id))
    }

    // if (this.props.destinations.length === 0) {
    //   await this.props.dispatch(getDestinations())
    // }
    if (this.props.communication_types.length === 0) {
      await this.props.dispatch(getCommunications())
    }
    if (this.props.print_options.length === 0) {
      await this.props.dispatch(getPrintOptions())
    }
    let { current_mailer } = this.props
    let updatedState = {
      ...this.initialState,
      ...current_mailer,
      selected_options: {
        ...Object.keys(this.props.print_options).reduce(
          (acc, o) => ({ ...acc, [o]: this.props.print_options[o].find(op => op.default === true)?.id }),
          {}
        ),
      },
      ...(current_mailer?.payer_type === 'User'
        ? {
            usersOpts: [
              { id: current_mailer.payer_id, value: current_mailer.payer.name, label: current_mailer.payer.name },
            ],
          }
        : { usersOpts: [] }),
      ...(current_mailer?.payer_type === 'Condominium'
        ? {
            condominiaOpts: [
              { id: current_mailer.payer_id, value: current_mailer.payer.name, label: current_mailer.payer.name },
            ],
          }
        : { condominiaOpts: [] }),
    }
    if (this.props.router.params.condominium_id) {
      let condominium = await getSingleCondominium(this.props.router.params.condominium_id)
      updatedState.user_id = condominium?.user?.id ?? ''
      updatedState.payer_type = 'Condominium'
      updatedState.payer_id = condominium?.id ?? ''
    }
    let calculatedHash = hash(updatedState)
    this.setState({ ...updatedState, form_hash: calculatedHash })
    this.initialHash = calculatedHash
  }

  createMS = async () => {
    let {
      selectedFile,
      title,
      selected_options,
      payer_id,
      payer_type,
      user_id: s_user_id,
      parent_id,
      iban,
      sdi,
    } = this.state
    let { my_user_id, isCondominiaAdmin } = this.props
    let user_id = s_user_id ?? my_user_id

    if (payer_type === 'User') {
      user_id = payer_id
      let result = await axios({
        url: `${config.SERVER_API_URL}/v1/users/${payer_id}?include=tax_datum`,
        method: 'GET',
      })
      let userData = jsonapiToData(result.data)
      await axios({
        url: `${config.SERVER_API_URL}/v1/users/${payer_id}`,
        method: 'PATCH',
        data: { tax_datum_attributes: { id: userData.data?.[0]?.tax_datum?.id, iban, sdi } },
      })
    }
    if (payer_type === 'Condominium') {
      let cond = await getSingleCondominium(payer_id)
      user_id = cond?.user_id ?? s_user_id ?? my_user_id
      await axios({
        url: `${config.SERVER_API_URL}/v1/condominia/${payer_id}`,
        method: 'PATCH',
        data: { iban, sdi },
      })
    }
    let formData = new FormData()
    formData.append('_jsonapi[typology]', 'equivalent')
    formData.append('_jsonapi[title]', title)
    formData.append('_jsonapi[payer_id]', payer_id)
    formData.append('_jsonapi[payer_type]', payer_type)
    formData.append('_jsonapi[attachments_attributes][][document]', selectedFile)
    if (!isCondominiaAdmin && s_user_id && parent_id) {
      formData.append('_jsonapi[user_id]', s_user_id)
      formData.append('_jsonapi[parent_id]', parent_id)
    }
    Object.keys(selected_options).forEach(o => {
      let pr_opt = selected_options[o]
      formData.append(
        '_jsonapi[attachments_attributes][][associable_print_options_attributes][][print_option_id]',
        pr_opt
      )
    })

    await this.props.dispatch(createMailer({ user_id, data: formData }))
    this.setState({ selectedFile: '', virtual_attachment: false })
    this.uploadForm = null
  }
  updateMS = async () => {
    let {
      selectedFile,
      virtual_attachment,
      title,
      selected_options,
      payer_id,
      payer_type,
      iban,
      sdi,
      user_id: s_user_id,
    } = this.state
    let { my_user_id, current_mailer } = this.props
    let user_id = current_mailer.user.id

    if (payer_type === 'User') {
      user_id = payer_id
      let result = await axios({
        url: `${config.SERVER_API_URL}/v1/users/${payer_id}?include=tax_datum`,
        method: 'GET',
      })
      let userData = jsonapiToData(result.data)
      await axios({
        url: `${config.SERVER_API_URL}/v1/users/${payer_id}`,
        method: 'PATCH',
        data: { tax_datum_attributes: { id: userData.data?.[0]?.tax_datum?.id, iban, sdi } },
      })
    }
    if (payer_type === 'Condominium') {
      let cond = await getSingleCondominium(payer_id)
      user_id = cond?.user_id ?? s_user_id ?? my_user_id
      await axios({
        url: `${config.SERVER_API_URL}/v1/condominia/${payer_id}`,
        method: 'PATCH',
        data: { iban, sdi },
      })
    }
    if (virtual_attachment === true) {
      let formData = new FormData()
      formData.append('_jsonapi[document]', selectedFile)
      Object.keys(selected_options).forEach(o => {
        let pr_opt = selected_options[o]
        formData.append('_jsonapi[associable_print_options_attributes][][print_option_id]', pr_opt)
      })
      await this.props.dispatch(createAttachment({ user_id, data: formData }))
    }
    let formData = new FormData()
    formData.append('_jsonapi[title]', title)
    formData.append('_jsonapi[payer_id]', payer_id)
    formData.append('_jsonapi[payer_type]', payer_type)
    await this.props.dispatch(updateMailer({ user_id, mailer_id: current_mailer.id, data: formData }))
    this.setState({ selectedFile: '', virtual_attachment: false })
    this.uploadForm = null
  }

  goForward = async () => {
    let { current_mailer } = this.props
    // se non sei amministratore di condominio non eseguire post
    // if (!isCondominiaAdmin) this.props.router.navigate(`/mailing/select_targets/${current_mailer.id}`)

    let { form_hash, ...rest } = this.state
    let actual_hash = hash(rest)
    let errors = validateFields(rest)
    if (Object.keys(errors).length === 0) {
      if (actual_hash === form_hash) {
        this.props.router.navigate(`/mailing/select_targets/${current_mailer.id}`)
      } else {
        if (Object.keys(current_mailer).length === 0) {
          await this.createMS()
        } else {
          await this.updateMS()
        }
        let { current_mailer: new_cm } = this.props
        if (new_cm.id) {
          this.props.router.navigate(`/mailing/select_targets/${new_cm.id}`)
        } else {
          error(t('store.common.create_mailer_error', 'Impossibile salvare le informazioni'))
        }
      }
      this.setState({ validated: true })
    } else {
      this.props.dispatch(updateErrors(errors))
      this.setState({ validated: true })
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.print_options !== this.props.print_options) {
      this.setState({
        selected_options: {
          ...Object.keys(this.props.print_options).reduce(
            (acc, o) => ({ ...acc, [o]: this.props.print_options[o].find(op => op.default === true)?.id }),
            {}
          ),
        },
      })
    }
    if (prevState.payer_id !== this.state.payer_id) {
      let sdi = ''
      let iban = ''
      let payer_id = this.state.payer_id
      let errors = { ...this.props.errors }
      if (this.state.payer_type === 'User') {
        let result = await axios({
          url: `${config.SERVER_API_URL}/v1/users/${payer_id}?include=tax_datum`,
          method: 'GET',
        })
        let userData = jsonapiToData(result.data)
        sdi = userData.data?.[0]?.tax_datum?.sdi ?? ''
        iban = userData.data?.[0]?.tax_datum?.iban ?? ''
      } else {
        let result = await axios({
          url: `${config.SERVER_API_URL}/v1/condominia/${payer_id}`,
          method: 'GET',
        })
        let condData = jsonapiToData(result.data)
        sdi = condData.data?.[0]?.sdi ?? ''
        iban = condData.data?.[0]?.iban ?? ''
      }
      this.setState({ sdi, iban })
      if (sdi) {
        delete errors.sdi
      }
      if (iban) {
        delete errors.iban
      }
      this.props.dispatch(updateErrors(errors))
    }
  }
  componentWillUnmount() {
    this.props.dispatch(updateErrors({}))
    this.props.dispatch(resetCurrentMailer())
  }

  setUploadForm = form => {
    this.uploadForm = form
  }

  uploadFile = async () => {
    this.setState({ virtual_attachment: true })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
    let errors = { ...this.props.errors }
    delete errors[e.target.name]
    this.props.dispatch(updateErrors({ ...errors }))
  }

  checkOptions = (o, id) => () => {
    this.setState({ selected_options: { ...this.state.selected_options, [o]: id } })
    let errors = { ...this.props.errors }
    delete errors['selected_options']
    this.props.dispatch(updateErrors({ ...errors }))
  }
  handleChangeDestination = id => () => {
    this.setState({ typology: id })
  }

  handleDelete = async () => {
    let { current_mailer } = this.props
    showConfirmDialog(
      'Elimina postalizzazione',
      'Sei sicuro di voler annullare la postalizzazione in corso? Tutti i dati inseriti andranno persi!',
      async () => {
        if (current_mailer.id) await this.props.dispatch(deleteMailer(current_mailer.id))
        this.props.navigate('/mailing')
      },
      () => {},
      'danger',
      'Annulla postalizzazione'
    )
  }
  FileUploadHandler = e => {
    this.setState({ selectedFile: e.target.files[0] })
    let errors = { ...this.props.errors }
    delete errors['selectedFile']
    this.props.dispatch(updateErrors({ ...errors }))
  }
  handleDeleteAttachment = id => async () => {
    await this.props.dispatch(deleteAttachment(id))
    await this.props.dispatch(getMailerAttachments())
    this.setState({ selectedFile: '' })
  }

  handleChangePayerId = async option => {
    this.setState({ payer_id: option?.id ?? '' })
  }
  handleChangePayerType = async (option, payer_id) => {
    this.setState({ payer_type: option?.id ?? '', payer_id })
  }
  handleChangeUser = option => {
    this.setState({ user_id: option?.id ?? '' })
  }
  handleChangeParent = option => {
    if (option) {
      let { payer_id = '', payer_type = '' } = option
      this.setState({ parent_id: option?.id ?? '', payer_id, payer_type })
    } else {
      this.setState({ parent_id: '', payer_id: '', payer_type: '', iban: '', sdi: '' })
    }
  }

  updateErrors = errors => {
    this.props.dispatch(updateErrors({ ...errors }))
  }

  render() {
    let {
      destinations,
      errors,
      print_options,
      trans,
      current_mailer,
      my_user_id,
      isAdmin,
      name,
      surname,
      isCondominiaAdmin,
      isSubaccount,
      roles,
    } = this.props
    let {
      selectedFileLabel,
      typology,
      validated,
      selected_options,
      payer_type,
      payer_id,
      selectedFile,
      title,
      virtual_attachment,
      parent_id,
      user_id,
      sdi,
      iban,
    } = this.state
    let { print_options: poTrans } = trans?.tables || {}
    let { attachments = [], current_state = 'open' } = current_mailer
    let allDisabled = current_state !== 'open'

    let main_attachment_id = current_mailer.main_attachment_id ?? attachments?.[0]?.id
    let main_attachment = attachments.find(el => parseInt(el.id) === parseInt(main_attachment_id))

    if (virtual_attachment === true) {
      let ptro = Object.keys(selected_options).map(k => ({
        category: k,
        value: print_options[k].find(el => el.id === selected_options[k]).value,
      }))
      main_attachment = { id: null, name: selectedFile.name, print_options: ptro }
    }

    return (
      <div className="d-flex flex-column w-100 overflow-auto m-2 ms-3 me-3 z-1 flex-1">
        <div className="d-flex flex-wrap gap-2 mb-2 w-100 justify-content-between">
          <div className="p-1">
            <h4 className="text-primary">
              <Trans i18nKey="mailing.modal_new_title">Nuova postalizzazione</Trans>
            </h4>
            <Breadcrumbs page={0} />
          </div>
        </div>
        <div className="d-flex mb-1 mt-1 flex-row gap-2 h-100 align-items-stretch" style={{ flexWrap: 'wrap' }}>
          <div className="border p-3 bg-white" style={{ flex: 1, minWidth: 900 }}>
            {!isCondominiaAdmin && !isSubaccount && (
              <div className="row mt-1">
                <div className="col-12 d-flex flex-column">
                  <div className="mb-2 fw-bold">
                    <Trans i18nKey="mailing.form.cost_center_label">Postalizzazione d'origine</Trans>
                  </div>
                  <MailParentSelector
                    user_id={user_id}
                    parent_id={parent_id}
                    onChangeUser={this.handleChangeUser}
                    onChangeParent={this.handleChangeParent}
                    isAdmin={isAdmin}
                    errors={errors}
                    updateErrors={this.updateErrors}
                    roles={roles}
                  />
                </div>
              </div>
            )}
            <div className="row mt-1">
              <div className="col-12 d-flex flex-column">
                <div className="mb-2 fw-bold">
                  <Trans i18nKey="mailing.form.cost_center_label">Centro di costo</Trans>
                </div>
                <CostCenterSelector
                  payer_type={payer_type}
                  payer_id={payer_id}
                  onChangePayerType={this.handleChangePayerType}
                  onChangePayer={this.handleChangePayerId}
                  isAdmin={isAdmin || isSubaccount}
                  user_id={!isCondominiaAdmin ? user_id : my_user_id}
                  userName={name}
                  userSurname={surname}
                  errors={errors}
                  updateErrors={this.updateErrors}
                  mailer_user_id={!isCondominiaAdmin ? user_id : ''}
                  roles={roles}
                />
              </div>
            </div>
            <div className="gap-2 row mb-3 w-100">
              <div className="col">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>
                    <Trans i18nKey="mailing.form.title">IBAN</Trans>
                  </Form.Label>
                  <span> *</span>
                  <Form.Control
                    name="iban"
                    placeholder={''}
                    value={iban}
                    onChange={this.handleChange}
                    isInvalid={validated && 'iban' in errors}
                    disabled={allDisabled}
                  />
                  <Form.Control.Feedback type="invalid">{errors.iban}</Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>
                    <Trans i18nKey="mailing.form.title">SDI</Trans>
                  </Form.Label>
                  <span> *</span>
                  <Form.Control
                    name="sdi"
                    placeholder={''}
                    value={sdi}
                    onChange={this.handleChange}
                    isInvalid={validated && 'sdi' in errors}
                    disabled={allDisabled}
                  />
                  <Form.Control.Feedback type="invalid">{errors.sdi}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
            {false && (
              <div className="row mt-1">
                <div className="col-12 d-flex flex-column">
                  <div className="mb-2 fw-bold">
                    <Trans i18nKey="mailing.form.destinations_label">Opzioni destinazione</Trans>
                  </div>
                  <div className="d-flex flex-row flex-wrap gap-2">
                    {destinations?.map(r => (
                      <Form.Check
                        key={`dest-${r.id}`}
                        inline
                        label={r.label}
                        name="tipology"
                        type="radio"
                        id={r.id}
                        checked={typology === r.id}
                        onChange={this.handleChangeDestination(r.id)}
                        disabled={allDisabled}
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="row mt-3 me-2">
              <div className="col">
                <Form.Group className="mt-3 mb-3">
                  <Form.Label>
                    <Trans i18nKey="mailing.form.title">Oggetto</Trans>
                  </Form.Label>
                  <span> *</span>
                  <Form.Control
                    name="title"
                    placeholder={t('mailing.form.title', 'Oggetto')}
                    value={title}
                    onChange={this.handleChange}
                    isInvalid={validated && 'title' in errors}
                    disabled={allDisabled}
                  />
                  <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="border p-3 bg-white" style={{ flex: 1, minWidth: 900 }}>
            <div className="d-flex justify-content-between">
              <div className="fw-bold">Allegato principale</div>
              <AddAttachment
                selectedFile={selectedFile}
                selectedFileLabel={selectedFileLabel}
                errors={errors}
                FileUploadHandler={this.FileUploadHandler}
                setUploadForm={this.setUploadForm}
                disabled={allDisabled || attachments.length >= 1}
                print_options={print_options}
                poTrans={poTrans}
                selected_options={selected_options}
                checkOptions={this.checkOptions}
                uploadFile={this.uploadFile}
              />
            </div>
            <div className="row mt-3">
              <div className="overflow-auto" style={{ height: '15rem' }}>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>File</th>
                      <th className="text-center">
                        <Trans i18nKey="table_column.actions">Opzioni</Trans>
                      </th>
                      <th>Download</th>
                      <th>Cancella</th>
                    </tr>
                  </thead>
                  <tbody>
                    {main_attachment && (
                      <tr style={{ height: '1rem' }} key={`dw-${main_attachment.id}`}>
                        <td style={{ maxWidth: '20rem' }}>{main_attachment.name}</td>
                        <td>
                          <ul>
                            {(main_attachment.print_options ?? []).map(p => (
                              <li key={p.value}>{poTrans?.[p.category]?.[p.value] ?? p.value}</li>
                            ))}
                          </ul>
                        </td>
                        <td className="text-center pt-2">
                          {virtual_attachment === false && (
                            <DownloadDocumentButton
                              className=""
                              documentUrl={`${config.SERVER_API_URL}/v1/mail_senders/${current_mailer.id}/attachments/${main_attachment.id}`}
                              filename={main_attachment.name}
                              variant="primary"
                              size="md"
                              iconRight="fa-download"
                            />
                          )}
                          {virtual_attachment === true && (
                            <DownloadDocumentButton
                              className=""
                              documentUrl={URL.createObjectURL(selectedFile)}
                              filename={main_attachment.name}
                              variant="primary"
                              size="md"
                              iconRight="fa-download"
                            />
                          )}
                        </td>
                        <td className="text-center pt-2">
                          {virtual_attachment === false && (
                            <Button onClick={this.handleDeleteAttachment(main_attachment.id)} disabled={allDisabled}>
                              Elimina <i className="fa fa-trash" />
                            </Button>
                          )}
                          {virtual_attachment === true && (
                            <Button
                              onClick={() => this.setState({ selectedFile: '', virtual_attachment: false })}
                              disabled={allDisabled}>
                              Elimina <i className="fa fa-trash" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    )}
                    {!main_attachment && (
                      <tr style={{ height: '4rem' }}>
                        <td colSpan="4" className="text-center p-3">
                          Nessun allegato inserito
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <div style={{ fontStyle: 'italic' }}>
                  * Gli allegati vengono inviati nella loro interezza ai destinatari selezionati
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 align-items-end mt-2" style={{ flex: 1 }}>
          <Button
            variant={current_mailer?.current_state === 'open' ? 'warning' : 'secondary'}
            className="float-md-end"
            onClick={this.handleDelete}
            disabled={current_mailer?.current_state !== 'open' && typeof current_mailer.current_state !== 'undefined'}>
            Cancella
          </Button>
          <div className="d-flex">
            <Link to={'/mailing'} className="me-3">
              <Button variant="secondary" className="float-md-end">
                <i className={`fa fa-arrow-left`} /> Indietro
              </Button>
            </Link>
            <Button variant="primary" className="float-md-end" onClick={this.goForward}>
              Procedi <i className={`fa fa-arrow-right`} />
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mstp)(withJWTSession(NewMailing))
