import SelectMunicipality from 'components/system_wide/select_municipality'
import i18next from 'i18next'
import validateFields from 'lib/validators/recipient'
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Trans } from 'react-i18next'

const { t } = i18next

const initialState = {
  name: '',
  fiscal_identifier: '',
  street: '',
  pec: '',
  email: '',
  region: '',
  province: '',
  cap: '',
  city_id: '',
  city: {},
  country_id: 1,
}

class AddRecipient extends React.Component {
  static defaultProps = {
    onSave: () => {},
    closeModal: () => {},
    recipient: {},
  }
  state = {
    rep: { typology: 'person', send_typology: 'paper', ...initialState },
    errors: {},
    validated: false,
  }
  componentDidMount() {
    if (Object.keys(this.props.recipient).length) {
      this.setState({ rep: { ...this.state.rep, ...this.props.recipient } })
    }
  }

  checkErrors = () => {
    let rep = { ...this.state.rep }
    let errors = validateFields(rep, this.props.validators)
    this.setState({ errors })
  }

  handleChange = ({ target: { name, value } }) => {
    let rep = { ...this.state.rep }
    rep[name] = value
    this.setState({ rep }, () => this.state.validated && this.checkErrors())
  }
  handleMunicipalityChange = values => {
    let rep = { ...this.state.rep, ...values }
    this.setState({ rep }, () => this.state.validated && this.checkErrors())
  }
  handleConfirm = () => {
    let errors = validateFields(this.state.rep, this.props.validators)
    if (Object.keys(errors).length) {
      this.setState({ errors, validated: true })
    } else {
      let { rep } = this.state
      this.props.onSave({ ...rep })
    }
  }

  render() {
    let { closeModal } = this.props
    let { errors } = this.state
    let { fiscal_identifier, cap, city_id, pec, email, street, name, id, city } = this.state.rep
    return (
      <Modal
        size="lg"
        backdrop="static"
        fullscreen="lg-down"
        centered
        show={true}
        onHide={closeModal}
        className="menu-dialog">
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <h4 className="text-primary p-1">
              <Trans i18nKey="mailing.add_recipient.title">{id ? 'Modifica' : 'Aggiungi'} destinatario</Trans>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-row">
          <div className="col-12">
            <div className="flex-fill position-relative container">
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-1">
                    <Form.Label>
                      <Trans i18nKey="mailing.add_recipient.nome">Nome</Trans>
                    </Form.Label>
                    <span> *</span>
                    <Form.Control
                      name="name"
                      placeholder={t('mailing.add_recipient.name', 'Nome')}
                      value={name}
                      onChange={this.handleChange}
                      isInvalid={'name' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-1">
                    <Form.Label>
                      <Trans i18nKey="mailing.add_recipient.fiscal_identifier">Identificativo fiscale</Trans>
                    </Form.Label>
                    <Form.Control
                      name="fiscal_identifier"
                      placeholder={t('mailing.add_recipient.fiscal_identifier', 'Identificativo fiscale')}
                      value={fiscal_identifier}
                      onChange={this.handleChange}
                      isInvalid={'fiscal_identifier' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.fiscal_identifier}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <Form.Group className="mt-1">
                    <Form.Label>
                      <Trans i18nKey="mailing.add_recipient.email">Email</Trans>
                    </Form.Label>
                    <Form.Control
                      name="email"
                      placeholder={t('mailing.add_recipient.email', 'Email')}
                      value={email}
                      onChange={this.handleChange}
                      isInvalid={'email' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-1">
                    <Form.Label>
                      <Trans i18nKey="mailing.add_recipient.email">PEC</Trans>
                    </Form.Label>
                    <Form.Control
                      name="pec"
                      placeholder={t('mailing.add_recipient.pec', 'PEC')}
                      value={pec}
                      onChange={this.handleChange}
                      isInvalid={'pec' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.pec}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row mt-4">
                <SelectMunicipality
                  city={city_id}
                  cityData={city}
                  cap={cap}
                  errors={errors}
                  onChange={this.handleMunicipalityChange}
                  showRequiredMarks={false}
                />
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mb-3">
                    <Form.Label>
                      <Trans i18nKey="mailing.add_recipient.street">Indirizzo</Trans>
                    </Form.Label>
                    <Form.Control
                      name="street"
                      placeholder={t('mailing.add_recipient.street', 'Indirizzo')}
                      value={street}
                      onChange={this.handleChange}
                      isInvalid={'street' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.street}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="text-danger">{errors.required_one}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} variant="secondary" className="me-auto">
            <Trans i18nKey="common.cancel_button">Annulla</Trans>
          </Button>
          <div style={{ fontSize: 14, marginRight: 10 }}>
            Compilare un campo tra email, PEC o indirizzo di spedizione. Questi attiveranno le relative modalità di
            invio.
          </div>
          {id && (
            <Button onClick={this.handleConfirm} variant="primary" disabled={false}>
              <Trans i18nKey="condominia.add_modal.confirm_button">Salva</Trans>
            </Button>
          )}
          {!id && (
            <Button onClick={this.handleConfirm} variant="primary" disabled={false}>
              <Trans i18nKey="condominia.add_modal.confirm_button">Aggiungi</Trans>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

export default AddRecipient
