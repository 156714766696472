import i18next from 'i18next'
import React from 'react'
import { connect } from 'react-redux'

import { getCity } from 'store/common'

const { t } = i18next

const mstp = state => {
  let { current_city } = state.common
  return {
    current_city,
  }
}

class ShowMunicipality extends React.Component {
  static defaultProps = {
    city: '',
    codiceIstat: null,
    cap: '',
    labels: {},
    current_city: { name: '', region: '', province: '' },
  }

  state = {
    labels: {
      region: t('common.form.region', 'Regione'),
      province: t('common.form.province', 'Provincia'),
      city: t('common.form.city', 'Città'),
      cap: t('common.form.cap', 'CAP'),
    },
  }

  async componentDidMount() {
    await this.props.dispatch(getCity(this.props.city))
  }

  render() {
    let { labels } = this.state
    let { cap, current_city } = this.props
    let { name: city, province, region } = current_city

    return (
      <>
        <div className="row pt-3 border-top">
          <div className="mb-2 col-lg-3 col-md-6 col-sm-12">
            <div className="text-muted modal-title">{labels.region}</div>
            <div>{region}</div>
          </div>
          <div className="mb-3 col-lg-3 col-md-6 col-sm-12">
            <div className="text-muted modal-title">{labels.province}</div>
            <div>{province}</div>
          </div>
          <div className="mb-3 col-lg-3 col-md-6 col-sm-12">
            <div className="text-muted modal-title">{labels.city}</div>
            <div>{city}</div>
          </div>
          <div className="mb-3 col-lg-3 col-md-6 col-sm-12">
            <div className="text-muted modal-title">{labels.cap}</div>
            <div>{cap ?? ''}</div>
          </div>
        </div>
      </>
    )
  }
}

export default connect(mstp)(ShowMunicipality)
