import config from 'config'
import axios from 'lib/axios'
import jsonapiToData from 'lib/jsonapi_to_data'
import React from 'react'
import { Form } from 'react-bootstrap'
import StyledSelect from '../../system_wide/styled_select'

const getKey = (obj, value) => Object.keys(obj || {}).find(k => obj?.[k] === value)

class CostCenterSelector extends React.Component {
  static defaultProps = {
    errors: {},
    allDisabled: false,
    parent_id: null,
    user_id: null,
    isAdmin: false,
    onChangeUser: () => {},
    onChangeParent: () => {},
    usersOpts: [],
    mailingOpts: [],
    roles: {},
  }

  state = {
    usersOpts: [],
    mailingOpts: [],
  }

  componentDidMount() {
    let { parent_id, user_id } = this.props
    if (user_id) {
      this.loadUsersOptions(user_id, true)
    }
    if (parent_id) {
      this.loadMailingOptions(parent_id)
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.user_id !== this.props.user_id && !prevProps.user_id) {
      this.loadUsersOptions(this.props.user_id, true)
    }
  }

  loadUsersOptions = async (inputValue, is_id = false) => {
    let { roles } = this.props
    let url = `${
      config.SERVER_API_URL
    }/v1/users?fields[tax_data][]=business_name&fields[tax_data][]=vat_number&fields[users][]=name&fields[users][]=surname&fields[users][]=tax_datum&include=tax_datum&filter[q]=${encodeURIComponent(
      inputValue
    )}&filter[with_role]=${getKey(roles, 'building_administrator')}`
    if (is_id)
      url = `${config.SERVER_API_URL}/v1/users/${encodeURIComponent(
        inputValue
      )}?fields[tax_data][]=business_name&fields[tax_data][]=vat_number&fields[users][]=name&fields[users][]=surname&fields[users][]=tax_datum&include=tax_datum&filter[with_role]=${getKey(
        roles,
        'building_administrator'
      )}`
    let response = await axios({ url, method: 'get' })
    let users_data = jsonapiToData(response.data)
    let usersOpts = users_data.data.map(r => ({
      id: r.id,
      value: r.id,
      label: `${r.name} ${r.surname}\n${r.tax_datum?.business_name ?? '---'}\n${
        r.tax_datum?.vat_number ?? 'Nessuna P.IVA'
      }\n`,
      chipLabel: `${r.tax_datum?.business_name ?? r.surname + ' ' + r.name}`,
    }))

    this.setState({ usersOpts })
    return usersOpts
  }

  loadMailingOptions = async inputValue => {
    let { user_id } = this.props
    let url = `${config.SERVER_API_URL}/v1/mail_senders/?filter[with_user]=${encodeURIComponent(
      user_id
    )}&filter[with_current_state]=delegated&fiter[without_children]=true`
    let responseM = await axios({ url, method: 'get' })
    let mailing_data = jsonapiToData(responseM.data)
    let mailingOpts = mailing_data.data.map(c => ({
      id: c.id,
      value: c.id,
      label: c.title,
      payer_id: c.payer_id,
      payer_type: c.payer_type,
    }))
    if (inputValue) {
      mailingOpts = mailingOpts.filter(c => c.id === inputValue)
    }
    this.setState({ mailingOpts })
  }

  handleChangeUser = async option => {
    let errors = { ...this.props.errors }
    delete errors['user_id']
    this.props.updateErrors({ ...errors })
    this.props.onChangeUser(option)

    let url2 = `${config.SERVER_API_URL}/v1/mail_senders/?filter[with_user]=${encodeURIComponent(
      option.id
    )}&filter[with_current_state]=delegated&fiter[without_children]=true`
    let responseM = await axios({ url: url2, method: 'get' })
    let mailing_data = jsonapiToData(responseM.data)
    let mailingOpts = mailing_data.data.map(c => ({
      id: c.id,
      value: c.id,
      label: c.title,
      payer_id: c.payer_id,
      payer_type: c.payer_type,
    }))
    this.setState({ mailingOpts })
  }

  handleChangeParent = option => {
    let errors = { ...this.props.errors }
    delete errors['parent_id']
    this.props.updateErrors({ ...errors })
    this.props.onChangeParent(option)
  }

  render() {
    let { errors, allDisabled, user_id, parent_id, title } = this.props
    let { usersOpts, mailingOpts } = this.state

    return (
      <>
        {title && <div className="mb-2 fw-bold">{title}</div>}
        <div className="gap-2 row mb-3 w-100">
          <div className="col">
            <StyledSelect
              className={`${'user_id' in errors ? 'is-invalid' : ''}`}
              valid={!('user_id' in errors)}
              placeholder={'Amministratore'}
              value={usersOpts.find(el => el.id.toString() === user_id.toString())}
              onChange={this.handleChangeUser}
              options={usersOpts}
              cacheOptions={false}
              loadOptions={this.loadUsersOptions}
              isClearable={true}
            />
            <Form.Control.Feedback type="invalid">{errors.user_id}</Form.Control.Feedback>
          </div>
          <div className="col">
            <StyledSelect
              className={`${'parent_id' in errors ? 'is-invalid' : ''}`}
              valid={!('parent_id' in errors)}
              placeholder={'Postalizzazione da collegare'}
              isDisabled={!user_id || allDisabled}
              value={mailingOpts.find(el => el.id.toString() === parent_id.toString())}
              onChange={this.handleChangeParent}
              options={mailingOpts}
              cacheOptions={false}
              isClearable={true}
            />
            <Form.Control.Feedback type="invalid">{errors.parent_id}</Form.Control.Feedback>
          </div>
        </div>
      </>
    )
  }
}

export default CostCenterSelector
