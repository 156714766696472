import AddRecipient from 'components/mailing/new_recipient'
import RecipientsTable from 'components/mailing/recipients_table'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { editRecipient, getCondominiumRecipients } from 'store/condominia'

const mstp = state => {
  let { edit_condominia } = state.condominia
  return { edit_condominia }
}

class ManageRecipients extends React.Component {
  state = {
    edit_recipient: {
      name: '',
      fiscal_identifier: '',
      street: '',
      pec: '',
      email: '',
      region: '',
      province: '',
      cap: '',
      city_id: '',
      country_id: 1,
    },
    edit_recipient_modal: false,
  }

  openEditRecipient = recipient => () => {
    let { id, name, fiscal_identifier, pec, email, address = {} } = recipient
    let { street, cap, city_id, country_id, city = {} } = address
    let { province_id = '', region_id = '' } = city
    let rep = {
      id,
      name,
      fiscal_identifier,
      street,
      pec,
      email,
      region: region_id,
      province: province_id,
      cap,
      city_id,
      city,
      country_id,
    }
    this.setState({ edit_recipient: rep, edit_recipient_modal: true })
  }
  openNewRecipient = () => this.setState({ edit_recipient_modal: true })

  addNewRecipient = async data => {
    let { edit_condominia } = this.props
    await this.props.dispatch(
      editRecipient({
        user_id: edit_condominia.user_id,
        condominium_id: edit_condominia.id,
        recipient: data,
      })
    )
    await this.props.dispatch(
      getCondominiumRecipients({
        user_id: edit_condominia.user_id,
        condominium_id: edit_condominia.id,
      })
    )
    this.setState({ edit_recipient: {}, edit_recipient_modal: false })
  }

  render() {
    let { condominium } = this.props
    let { edit_recipient_modal, edit_recipient } = this.state
    return (
      <>
        <Modal
          size="lg"
          backdrop="static"
          centered
          show={true}
          onHide={this.props.onCloseModal}
          className="menu-dialog">
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title>Gestione rubrica</Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-row">
            <RecipientsTable
              enable_edit={true}
              recipients={condominium?.recipients ?? []}
              openEditRecipient={this.openEditRecipient}
            />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button onClick={this.props.onCloseModal} variant="secondary">
              <Trans i18nKey="common.close_button">Chiudi</Trans>
            </Button>
            <Button onClick={this.openNewRecipient} variant="primary">
              <Trans i18nKey="common.add_button">Aggiungi nuovo</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
        {edit_recipient_modal && (
          <AddRecipient
            recipient={edit_recipient}
            closeModal={() => this.setState({ edit_recipient_modal: false })}
            onSave={this.addNewRecipient}
            validators={{}}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(ManageRecipients)
