import * as React from 'react'
import cookies from 'browser-cookies'
import { Button, Card, Form, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { tryLogin } from 'store/login'
import Footer from './footer'

import './index.css'
import logo from 'assets/images/logo.svg'
import PasswordInput from 'components/system_wide/password_input'

class Login extends React.Component {
  state = { email: '', password: '', error: '' }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.handleSubmitLogin()
    }
  }

  handleChange = ({ target }) => {
    let { name, value } = target
    this.setState({ [name]: value, error: '' })
  }

  handleSubmitLogin = async () => {
    let { email, password } = this.state
    if (email !== '' && password !== '') {
      this.props.dispatch(tryLogin({ email, password }))
    } else {
      this.setState({ error: 'Email o password vuoti!' })
    }
  }

  componentDidMount() {
    cookies.erase('conhive-auth')
  }

  render() {
    let { email, password, error } = this.state
    let { loading, message_type, message } = this.props.loginData

    return (
      <div className="d-flex justify-content-center align-items-center vh-100 flex-column">
        <Card style={{ width: '28rem', backgroundColor: '#f2f2f2' }} className="p-5 pb-1 mt-auto text-center">
          <Card.Img variant="top" src={logo} />
          <Card.Body className="mt-5">
            <Alert variant={message_type} show={message !== ''} transition={false}>
              {message}
            </Alert>
            <Alert variant={'danger'} show={error !== ''} transition={false}>
              {error}
            </Alert>
            <Form onKeyDown={this.handleKeyDown}>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  name="email"
                  type="text"
                  placeholder="Nome utente o email"
                  onChange={this.handleChange}
                  value={email}
                  autoFocus={true}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <PasswordInput name="password" placeholder="Password" onChange={this.handleChange} value={password} />
              </Form.Group>
              <Button
                variant="primary"
                className="w-100 rounded-3 mt-2 mb-2"
                disabled={loading === true}
                onClick={this.handleSubmitLogin}>
                {loading === true ? <span className="spinner-border spinner-border-sm"></span> : 'ACCEDI'}
              </Button>
            </Form>
            <h5 className="hr-text">
              <span>oppure</span>
            </h5>
            <div className="fs-7 text-primary">
              <Link to="/forgot_password" className="text-decoration-none">
                Hai dimenticato la password?
              </Link>
            </div>
          </Card.Body>
        </Card>
        <Footer />
      </div>
    )
  }
}

export default Login
