import { HeaderModal } from 'components/system_wide/header_modal'
import SelectMunicipality from 'components/system_wide/select_municipality'
import StyledSelect from 'components/system_wide/styled_select'
import i18next from 'i18next'
import { isAdmin as _isAdmin, getRoleIdByName, isAdmin, isNoTaxDatum, isRecapitista } from 'lib/utils'
import validateFields from 'lib/validators/user'
import hash from 'object-hash'
import React from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { updateErrors } from 'store/users'

import config from 'config'
import axios from 'lib/axios'
import jsonapiToData from 'lib/jsonapi_to_data'

const { t } = i18next

const mstp = state => {
  let { role_id } = state.userInfo
  let { roles = {}, rolesEntity = {} } = state.usersData
  let isLoggedAdmin = _isAdmin(role_id, roles)
  let isLoggedRecapitista = isRecapitista(role_id, roles)
  return { isLoggedAdmin, rolesEntity, role_id, isLoggedRecapitista }
}

const initialState = {
  id: null,
  name: '',
  surname: '',
  email: '',
  role_id: '',
  parent_id: '',
  tax_datum_attributes: {
    business_name: '',
    vat_number: '',
    street_address: '',
    cap: '',
    city_id: '',
    region: '',
    province: '',
    phone: '',
    mobile_phone: '',
    email_contact: '',
    sdi: '',
    iban: '',
    country_id: 1,
  },
}

class UserForm extends React.Component {
  static defaultProps = {
    onCloseForm: () => {},
    onSave: () => {},
    errors: {},
    user: initialState,
  }

  state = {
    user: initialState,
    user_hash: '',
    enableParentSelect: false,
    enableTaxDatum: false,
    usersOpts: [],
    usersOptsOld: [],
  }

  canCRUD = roleId => {
    let { role_id, roles } = this.props
    let role_permissions = {
      admin: () => true,
      technician: role => !['admin', 'technician', 'delivery_person'].includes(role),
      delivery_person: role => !['admin', 'technician', 'delivery_person'].includes(role),
      building_administrator: role => role === 'subaccount',
    }

    let ownerRole = roles[role_id]
    let crudRole = roles[roleId]

    return role_permissions[ownerRole] ? role_permissions[ownerRole](crudRole) : false
  }

  componentDidMount() {
    if (this.props.isCondominiaAdmin === true) {
      let { roles } = this.props
      let roleId = Object.keys(roles).find(s => roles[s] === 'subaccount')
      this.setState({ user: { ...this.state.user, role_id: roleId } })
    }
  }

  componentDidUpdate = prevProps => {
    if (prevProps.user.id !== this.props.user.id) {
      const tax_datum_attributes = {
        ...this.props.user.tax_datum_attributes,
        email_contact: this.props.user?.tax_datum?.email,
      }

      const user = {
        ...this.state.user,
        ...this.props.user,
        tax_datum_attributes,
      }

      let user_hash = hash(user)
      this.setState({ user, user_hash })
      this.initialHash = hash(user)

      if (user?.parent?.id) {
        let usersOptsOld = [
          {
            id: user?.parent?.id,
            value: user?.parent?.id,
            label: `${user?.parent?.name} ${user?.parent?.surname}\n${
              user?.parent?.tax_datum?.business_name ?? '---'
            }\n${user?.parent?.tax_datum?.vat_number ?? 'Nessuna P.IVA'}\n`,
            chipLabel: `${user?.parent?.tax_datum?.business_name ?? user?.parent?.surname + ' ' + user?.parent?.name}`,
          },
        ]
        this.setState({ usersOptsOld })
      }

      this.handleUserRole(user)
    }
  }

  onChangeParent = selected => {
    const user = {
      ...this.state.user,
      parent_id: selected?.value || '',
    }
    this.setState({ user }, () => this.updateHash())
  }

  handleUserRole = user => {
    let { role_id, parent } = user
    let { rolesEntity, isLoggedAdmin, isLoggedRecapitista } = this.props

    if (role_id && rolesEntity.length > 0) {
      let entRoles = rolesEntity.filter(item => {
        return item.tax_datum_required === true && item.id === role_id
      })
      this.setState({
        enableTaxDatum:
          entRoles.length > 0 || (entRoles.length > 0 && (isLoggedAdmin === true || isLoggedRecapitista === true)),
      })
    }

    if (isLoggedAdmin || isLoggedRecapitista) {
      let isRole = rolesEntity.find(s => s.codename === 'subaccount')?.id
      this.setState({ enableParentSelect: isRole === role_id || (isRole === role_id && parent.id) })
    }
  }

  updateHash = () => {
    let { user } = this.state
    this.setState({ user_hash: hash(user) })
  }

  handleEmailChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    user[name] = value
    let tax_datum_attributes = { ...user.tax_datum_attributes }
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }

  handleChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    user[name] = value
    this.setState({ user }, () => this.updateHash())
  }
  handleTaxAttributesChange = ({ target: { name, value } }) => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }

    delete errors[name]
    this.props.dispatch(updateErrors({ errors }))
    name = name.replace('tax_datum_attributes.', '')
    const tax_datum_attributes = {
      ...user.tax_datum_attributes,
      [name]: value,
      ...(name === 'email_contact' && { email: value }),
    }
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }
  handleSelectChange = e => {
    let errors = { ...this.props.errors }
    delete errors['role_id']
    this.props.dispatch(updateErrors({ errors }))
    let user = { ...this.state.user, role_id: e.target.value }
    this.setState({ user, user_hash: hash(user) })
    this.handleUserRole(user)
  }
  handleMunicipalityChange = values => {
    let user = { ...this.state.user }
    let errors = { ...this.props.errors }
    let tax_datum_attributes = { ...user.tax_datum_attributes, ...values }
    Object.keys(values).forEach(k => delete errors[k])
    this.props.dispatch(updateErrors({ errors }))
    this.setState({ user: { ...user, tax_datum_attributes } }, () => this.updateHash())
  }

  loadUsersOptions = async inputValue => {
    let url = `${
      config.SERVER_API_URL
    }/v1/users?fields[tax_data][]=business_name&fields[tax_data][]=vat_number&fields[users][]=name&fields[users][]=surname&fields[users][]=tax_datum&include=tax_datum&filter[q]=${encodeURIComponent(
      inputValue
    )}&filter[with_role]=${getRoleIdByName('building_administrator', this.props.roles)}`
    let response = await axios({ url, method: 'get' })
    let users_data = jsonapiToData(response.data)
    let usersOpts = users_data.data.map(c => ({
      id: c.id,
      value: c.id,
      label: `${c.name} ${c.surname}\n${c.tax_datum?.business_name ?? '---'}\n${
        c.tax_datum?.vat_number ?? 'Nessuna P.IVA'
      }\n`,
      chipLabel: `${c.tax_datum?.business_name ?? c.surname + ' ' + c.name}`,
    }))

    this.setState({ usersOpts })
    return usersOpts
  }

  isFormValid = () => {
    let { name, surname, email, role_id, parent_id } = this.state.user
    let { enableParentSelect, enableTaxDatum } = this.state
    let { vat_number, business_name } = this.state.user.tax_datum_attributes
    return (
      name !== '' &&
      surname !== '' &&
      email !== '' &&
      role_id !== '' &&
      (enableTaxDatum === false || (enableTaxDatum === true && vat_number !== '' && business_name !== '')) &&
      (enableParentSelect === false || (enableParentSelect === true && parent_id !== ''))
    )
  }

  handleConfirm = () => {
    let { user } = this.state
    let { roles } = this.props
    let isUserAdmin = isAdmin(user.role_id, roles)
    let isNoMoreInfo = isNoTaxDatum(user.role_id, roles)
    let errors = validateFields(
      {
        ...user,
        vat_number: user.tax_datum_attributes?.vat_number,
        business_name: user.tax_datum_attributes?.business_name,
      },
      isUserAdmin || isNoMoreInfo || this.props.isCondominiaAdmin
    )
    if (Object.keys(errors).length) {
      this.props.dispatch(updateErrors({ errors }))
    } else {
      let { deactivated, tax_datum_attributes, ...rest } = this.state.user
      let { region, province, ...tax_datum } = tax_datum_attributes
      this.props.onSave({ ...rest, tax_datum_attributes: { ...tax_datum } })
    }
    this.setState({ validated: true })
  }

  render() {
    let { validated, user, enableTaxDatum, enableParentSelect, usersOpts, usersOptsOld } = this.state
    let { id, name, surname, email, role_id, tax_datum_attributes, parent_id } = user
    let {
      street_address = '',
      phone = '',
      cap,
      city_id,
      city,
      vat_number = '',
      business_name = '',
      mobile_phone = '',
      sdi = '',
      iban = '',
      email_contact,
    } = tax_datum_attributes
    let { roles, errors, tRoles, isCondominiaAdmin } = this.props
    let title = id
      ? t('users.user_form.edit_title', 'Modifica utente')
      : t('users.user_form.create_title', 'Crea utente')

    usersOpts = usersOpts.length > 0 ? usersOpts : usersOptsOld
    return (
      <>
        <HeaderModal title={title} onClose={this.props.closeForm} />
        <div className="flex-fill position-relative overflow-auto container">
          {errors?.from_server?.length > 0 && (
            <Alert variant="danger">
              <Alert.Heading>Error:</Alert.Heading>
              <ul>
                {errors.from_server.map((err, i) => (
                  <li key={`err-${i}`}>{err.detail}</li>
                ))}
              </ul>
            </Alert>
          )}
          <div className="row">
            <div className="col-3 pt-4">
              <Form.Label>
                <Trans i18nKey="users.user_form.role">Seleziona ruolo dell'utente: </Trans>
                <span> *</span>
              </Form.Label>
            </div>
            <div className="col-5">
              <Form.Group className="mt-3 mb-1">
                <Form.Select
                  value={role_id}
                  onChange={this.handleSelectChange}
                  disabled={isCondominiaAdmin === true}
                  isInvalid={validated && 'role_id' in errors}>
                  <option value="">{t('user.user_form.not_role', 'Nessun ruolo')}</option>
                  {roles &&
                    Object.entries(roles).map(
                      role =>
                        this.canCRUD(role[0]) && (
                          <option key={`rol-opt-${role[0]}`} value={role[0]}>
                            {tRoles[role[1]] ?? role[1]}
                          </option>
                        )
                    )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.role_id}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          {enableParentSelect === true && (
            <div className="row">
              <div className="col-3 pt-4">
                <Form.Label>
                  <Trans i18nKey="users.user_form.role">Seleziona amministratore: </Trans>
                  <span> *</span>
                </Form.Label>
              </div>
              <div className="col-5">
                <Form.Group className="mt-3 mb-1">
                  <StyledSelect
                    isClearable
                    placeholder={'Seleziona amministratore'}
                    value={usersOpts.find(o => parseInt(o.id) === parseInt(parent_id))}
                    onChange={this.onChangeParent}
                    options={usersOpts}
                    shouldTruncate={true}
                    loadOptions={this.loadUsersOptions}
                  />
                  <Form.Control.Feedback type="invalid">{errors.role_id}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          )}

          <div className="row">
            <div className={isCondominiaAdmin ? 'col-6' : 'col'}>
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.name">Nome</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="name"
                  placeholder={t('users.user_form.name', 'Nome')}
                  value={name}
                  onChange={this.handleChange}
                  isInvalid={validated && 'name' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className={isCondominiaAdmin ? 'col-6' : 'col'}>
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.surname">Cognome</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="surname"
                  placeholder={t('users.user_form.surname', 'Cognome')}
                  value={surname}
                  onChange={this.handleChange}
                  isInvalid={validated && 'surname' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>
              </Form.Group>
            </div>
            <div className={isCondominiaAdmin ? 'col-6' : 'col'}>
              <Form.Group className="mt-3 mb-3">
                <Form.Label>
                  <Trans i18nKey="users.user_form.email">Email</Trans>
                </Form.Label>
                <span> *</span>
                <Form.Control
                  name="email"
                  placeholder={t('users.user_form.email', 'Email')}
                  value={email}
                  onChange={this.handleEmailChange}
                  isInvalid={validated && 'email' in errors}
                />
                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
              </Form.Group>
            </div>
          </div>
          <>
            <div className="mt-3 mb-2 pb-0 border-bottom text-primary">
              <b>Dati fiscali</b>
            </div>
            <div style={{ opacity: !enableTaxDatum ? 0.3 : 1 }}>
              <div className="row">
                <div className="col col-7">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.business_name">Ragione sociale</Trans>
                    </Form.Label>
                    <span> *</span>
                    <Form.Control
                      name="tax_datum_attributes.business_name"
                      placeholder={t('users.user_form.business_name', 'Ragione sociale')}
                      value={business_name}
                      isInvalid={validated && 'tax_datum_attributes.business_name' in errors}
                      onChange={this.handleTaxAttributesChange}
                      disabled={!enableTaxDatum}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['tax_datum_attributes.business_name']}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col col-5">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.email_contact">Email di contatto</Trans>
                    </Form.Label>
                    <Form.Control
                      name="tax_datum_attributes.email_contact"
                      placeholder={t('users.user_form.email_contact', 'Email di contatto')}
                      value={email_contact}
                      isInvalid={validated && 'tax_datum_attributes.email_contact' in errors}
                      onChange={this.handleTaxAttributesChange}
                      disabled={!enableTaxDatum}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['tax_datum_attributes.email_contact']}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col col-3">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.vat_number">Partita IVA</Trans>
                    </Form.Label>
                    <span> *</span>
                    <Form.Control
                      name="tax_datum_attributes.vat_number"
                      placeholder={t('users.user_form.vat_number', 'Partita IVA')}
                      value={vat_number}
                      onChange={this.handleTaxAttributesChange}
                      isInvalid={validated && 'tax_datum_attributes.vat_number' in errors}
                      disabled={!enableTaxDatum}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors['tax_datum_attributes.vat_number']}
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col col-3">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.sdi">SDI</Trans>
                    </Form.Label>
                    <Form.Control
                      name="sdi"
                      placeholder={t('users.user_form.sdi', 'SDI')}
                      value={sdi}
                      onChange={this.handleTaxAttributesChange}
                      disabled={!enableTaxDatum}
                    />
                  </Form.Group>
                </div>
                <div className="col col-6">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.iban">IBAN</Trans>
                    </Form.Label>
                    <Form.Control
                      name="iban"
                      placeholder={t('users.user_form.iban', 'IBAN')}
                      value={iban}
                      onChange={this.handleTaxAttributesChange}
                      disabled={!enableTaxDatum}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.street_address">Indirizzo</Trans>
                    </Form.Label>
                    <Form.Control
                      name="street_address"
                      placeholder={t('users.user_form.street_address', 'Indirizzo')}
                      value={street_address}
                      onChange={this.handleTaxAttributesChange}
                      isInvalid={validated && 'street_address' in errors}
                      disabled={!enableTaxDatum}
                    />
                    <Form.Control.Feedback type="invalid">{errors.street_address}</Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <SelectMunicipality
                  city={city_id}
                  cityData={city}
                  cap={cap}
                  errors={errors}
                  onChange={this.handleMunicipalityChange}
                  isReadOnly={!enableTaxDatum}
                  isDisabled={!enableTaxDatum}
                />
              </div>
              <div className="row">
                <div className="col">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.tel">Telefono</Trans>
                    </Form.Label>
                    <Form.Control
                      name="phone"
                      placeholder={t('users.user_form.tel', 'Telefono')}
                      value={phone}
                      onChange={this.handleTaxAttributesChange}
                      disabled={!enableTaxDatum}
                    />
                  </Form.Group>
                </div>
                <div className="col">
                  <Form.Group className="mt-2 mb-3">
                    <Form.Label>
                      <Trans i18nKey="users.user_form.mobile_phone">Cellulare</Trans>
                    </Form.Label>
                    <Form.Control
                      name="mobile_phone"
                      placeholder={t('users.user_form.mobile_phone', 'Cellulare')}
                      value={mobile_phone}
                      onChange={this.handleTaxAttributesChange}
                      disabled={!enableTaxDatum}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="border-top pt-2">
          <Button
            className="float-end"
            variant="primary"
            disabled={!this.isFormValid() || this.initialHash === this.state.user_hash}
            onClick={this.handleConfirm}>
            <Trans i18nKey="common.button_confirm">Salva</Trans>
          </Button>
          <Button className="ms-auto me-2" variant="secondary" onClick={this.props.closeForm}>
            <Trans i18nKey="common.button_cancel">Annulla</Trans>
          </Button>
        </div>
      </>
    )
  }
}

export default connect(mstp)(UserForm)
