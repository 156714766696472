import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ReactNotifications } from 'react-notifications-component'
import { withTranslation } from 'react-i18next'
import { ConfirmDialog } from 'components/system_wide/confirm_dialog'
import { browserName } from 'react-device-detect'

import store from 'store'
import NotFound from './pages/notfound'
import Login from './pages/login'
import ResetPassword from './pages/login/reset_password'
import ForgotPassword from './pages/login/forgot_password'
import Home from './pages/home'
import Contracts from './pages/contracts'
import Transfers from './pages/transfers'
import Mailing from './pages/mailing'
import Condominia from './pages/condominia'
import EconomicSummary from './pages/economic_summary'
import Stats from './pages/stats'
import Users from './pages/users'
import UnsupportedBrowser from './components/system_wide/unsupported_browser'

import './scss/globals.scss'

class App extends React.PureComponent {
  render() {
    if (browserName === 'Edge') return <UnsupportedBrowser />
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ReactNotifications />
          <Routes>
            <Route path="*" element={<NotFound />} />
            <Route path="/" element={<Home />} />
            <Route path="/condominia" element={<Condominia />} />
            <Route path="/contracts" element={<Contracts />} />
            <Route path="/transfers" element={<Transfers />} />
            <Route path="/mailing/*" element={<Mailing />} />
            <Route path="/economic_summary" element={<EconomicSummary />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/users" element={<Users />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot_password" element={<ForgotPassword />} />
            <Route path="/users/password/edit" element={<ResetPassword />} />
            <Route path="logout" element={<Login />} />
          </Routes>
          <ConfirmDialog />
        </BrowserRouter>
      </Provider>
    )
  }
}

export default withTranslation()(App)
