import * as React from 'react'
import { connect } from 'react-redux'
import { HexGrid, Layout, Hexagon } from 'react-hexgrid'
import { isCondominiaAdmin, isDev, isSubaccount } from 'lib/utils'

import 'scss/hexagon.css'
import lista_condomini from '../../assets/images/home/lista-condomini.png'
import postalizzazione from '../../assets/images/home/postalizzazione.png'
import contratti from '../../assets/images/home/contratti.png'
import lista_trasferimenti from '../../assets/images/home/lista-trasferimenti.png'
import statistiche from '../../assets/images/home/statistiche.png'
import lista_utenti from '../../assets/images/home/lista-utenti.png'

const mstp = state => {
  let { roles } = state.usersData
  return {
    userInfo: state.userInfo,
    isCondominiaAdmin: isCondominiaAdmin(state.userInfo?.role_id, roles),
    isSubaccount: isSubaccount(state.userInfo?.role_id, roles),
  }
}

class HexagonGrid extends React.Component {
  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  handleResize = () => {
    window.location.reload()
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize, false)
  }
  render() {
    let { navigate, userInfo, isCondominiaAdmin, isSubaccount } = this.props
    const { innerWidth: width, innerHeight: height } = window
    let offsetX = 19
    let offsetY = 19
    let originX = 15
    let postalization_enabled = userInfo?.info?.enabled_features?.postalization === true
    if (!isCondominiaAdmin) postalization_enabled = true

    if (width >= 1100 && width <= 1460) {
      offsetX -= 3
      offsetY -= 3
      originX += 15
    }
    if (width < 1100) {
      offsetX -= 4
      offsetY -= 4
      originX += 10
    }
    return (
      <HexGrid width={width - 60} height={height - 55} viewBox="10 -70 100 100">
        <Layout size={{ x: offsetX, y: offsetY }} flat={false} spacing={1.03} origin={{ x: originX, y: -35 }}>
          <Hexagon q={1} r={-1} s={0} className="grey" />
          <Hexagon q={2} r={-1} s={0} className="grey" />
          <Hexagon q={3} r={-1} s={0} className="grey" />
          <Hexagon q={-1} r={0} s={0} className="grey" />
          <Hexagon q={0} r={0} s={0} onClick={() => navigate('/condominia')}>
            <image x="-10" y="-10" href={lista_condomini} height="20" width="20" />
          </Hexagon>
          <Hexagon q={1} r={0} s={0} className="grey" />
          <Hexagon
            q={1}
            r={0}
            s={0}
            className={`${postalization_enabled ? '' : 'darkgrey'}`}
            onClick={() => (postalization_enabled ? navigate('/mailing') : null)}>
            <image x="-13" y="-13.4" href={postalizzazione} height="26" width="26" />
          </Hexagon>
          <Hexagon q={2} r={0} s={0} onClick={() => navigate('/contracts')}>
            <image x="-10" y="-10" href={contratti} height="20" width="20" />
          </Hexagon>
          <Hexagon q={3} r={0} s={0} className="grey" />
          <Hexagon q={-2} r={1} s={0} className="grey" />
          <Hexagon q={-1} r={1} s={0} className="grey" />
          <Hexagon q={0} r={1} s={0} className="grey" />
          <Hexagon q={0} r={1} s={0} onClick={() => navigate('/transfers')}>
            <image x="-12" y="-12" href={lista_trasferimenti} height="24" width="24" />
          </Hexagon>
          <Hexagon
            q={1}
            r={1}
            s={0}
            className={isDev() ? '' : 'darkgrey'}
            onClick={() => isDev() && navigate('/stats')}>
            <image x="-12" y="-12" href={statistiche} height="24" width="24" />
          </Hexagon>
          <Hexagon q={2} r={1} s={0} className="grey" />
          {!isSubaccount && (
            <Hexagon q={2} r={1} s={0} onClick={() => navigate('/users')}>
              <image x="-12" y="-12" href={lista_utenti} height="24" width="24" />
            </Hexagon>
          )}
          <Hexagon q={3} r={1} s={0} className="grey" />
          <Hexagon q={-1} r={2} s={0} className="grey" />
          <Hexagon q={0} r={2} s={0} className="grey" />
          <Hexagon q={1} r={2} s={0} className="grey" />
          <Hexagon q={3} r={2} s={0} className="grey" />
        </Layout>
      </HexGrid>
    )
  }
}

export default connect(mstp)(HexagonGrid)
