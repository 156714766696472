import config from 'config'
import axios from 'lib/axios'
import jsonapiToData from 'lib/jsonapi_to_data'
import React from 'react'
import { Form } from 'react-bootstrap'
import StyledSelect from '../../system_wide/styled_select'

const cost_centers = [
  { id: 'User', value: 'User', label: `Amministratore` },
  { id: 'Condominium', value: 'Condominium', label: `Condominio` },
]

const getKey = (obj, value) => Object.keys(obj || {}).find(k => obj?.[k] === value)

class CostCenterSelector extends React.Component {
  static defaultProps = {
    userName: '',
    userSurname: '',
    errors: {},
    allDisabled: false,
    payer_type: '',
    user_id: null,
    isAdmin: false,
    onChangePayer: () => {},
    onChangePayerType: () => {},
    usersOpts: [],
    condominiaOpts: [],
    roles: {},
    shouldTruncate: false,
  }

  state = {
    usersOpts: [],
    condominiaOpts: [],
  }

  componentDidUpdate(prevProps) {
    let { payer_type, payer_id } = this.props
    if (payer_type && payer_id && payer_type !== prevProps.payer_type && payer_id !== prevProps.payer_id) {
      if (this.props.payer_type === 'User') {
        this.loadUsersOptions(payer_id, true)
      } else {
        this.loadCondominiaOptions(payer_id, true)
      }
    }
  }

  loadUsersOptions = async (inputValue, is_id = false) => {
    let { roles } = this.props
    let url = `${config.SERVER_API_URL}/v1/users/${encodeURIComponent(
      inputValue
    )}?fields[tax_data][]=business_name&fields[tax_data][]=vat_number&fields[users][]=name&fields[users][]=surname&fields[users][]=tax_datum&include=tax_datum&filter[with_role]=${getKey(
      roles,
      'building_administrator'
    )}`
    if (!is_id) {
      url = `${
        config.SERVER_API_URL
      }/v1/users?fields[tax_data][]=business_name&fields[tax_data][]=vat_number&fields[users][]=name&fields[users][]=surname&fields[users][]=tax_datum&include=tax_datum&filter[q]=${encodeURIComponent(
        inputValue
      )}&filter[with_role]=${getKey(roles, 'building_administrator')}`
    }
    if (is_id)
      url = `${config.SERVER_API_URL}/v1/users/${encodeURIComponent(
        inputValue
      )}?fields[tax_data][]=business_name&fields[tax_data][]=vat_number&fields[users][]=name&fields[users][]=surname&fields[users][]=tax_datum&include=tax_datum&filter[with_role]=${getKey(
        roles,
        'building_administrator'
      )}`
    let response = await axios({ url, method: 'get' })
    let users_data = jsonapiToData(response.data)
    let usersOpts = users_data.data.map(r => ({
      id: r.id,
      value: r.id,
      label: `${r.name} ${r.surname}\n${r.tax_datum?.business_name ?? '---'}\n${
        r.tax_datum?.vat_number ?? 'Nessuna P.IVA'
      }\n`,
      chipLabel: `${r.tax_datum?.business_name ?? r.surname + ' ' + r.name}`,
    }))
    this.setState({ usersOpts })
    return usersOpts
  }
  loadCondominiaOptions = async (inputValue, is_id = false) => {
    let { mailer_user_id } = this.props
    let url = `${config.SERVER_API_URL}/v1/condominia`
    let params = `?fields[condominia][]=name&fields[condominia][]=user_id&filter[q]=${encodeURIComponent(inputValue)}`

    if (is_id) params = `/${encodeURIComponent(inputValue)}?fields[condominia][]=name&fields[condominia][]=user_id`
    if (mailer_user_id) params += `&filter[with_user]=${mailer_user_id}`

    url = url + params
    let response = await axios({ url, method: 'get' })
    let condominia_data = jsonapiToData(response.data)
    let condominiaOpts = condominia_data.data.map(c => ({
      id: c.id,
      value: c.id,
      label: c.name,
      user_id: c.user_id,
    }))
    this.setState({ condominiaOpts })
    return condominiaOpts
  }

  handleChangePayerType = option => {
    let { isAdmin, user_id, userName, userSurname } = this.props
    let usersOpts = []
    let payer_id = ''
    if (option?.id === 'User' && !isAdmin) {
      payer_id = user_id
      usersOpts = [{ id: user_id, label: `${userSurname} ${userName}`, value: user_id }]
    }
    this.setState({ payer_type: option?.id, payer_id, usersOpts, condominiaOpts: [] })
    let errors = { ...this.props.errors }
    delete errors['payer_type']
    this.props.onChangePayerType(option, payer_id)
    this.props.updateErrors({ ...errors })
  }
  handleChangePayer = option => {
    let errors = { ...this.props.errors }
    delete errors['payer_id']
    this.props.onChangePayer(option)
    this.props.updateErrors({ ...errors })
  }

  render() {
    let { errors, allDisabled, payer_type, payer_id, user_id, isAdmin, title, shouldTruncate } = this.props
    let { usersOpts, condominiaOpts } = this.state

    return (
      <>
        {title && <div className="mb-2 fw-bold">{title}</div>}
        <div className="gap-1 row mb-3 w-100">
          <div className="col">
            <StyledSelect
              className={`${'payer_type' in errors ? 'is-invalid' : ''}`}
              valid={!('payer_type' in errors)}
              placeholder={'Centro di costo'}
              isDisabled={allDisabled}
              value={cost_centers.find(el => el.id.toString() === payer_type.toString())}
              onChange={this.handleChangePayerType}
              options={cost_centers}
              isClearable={true}
            />
            <Form.Control.Feedback type="invalid">{errors.payer_type}</Form.Control.Feedback>
          </div>
          <div className="col">
            <StyledSelect
              className={`${'payer_id' in errors ? 'is-invalid' : ''}`}
              valid={!('payer_id' in errors)}
              placeholder={'Cerca...'}
              isDisabled={!payer_type || allDisabled || (payer_id.toString() === user_id.toString() && !isAdmin)}
              value={
                usersOpts.find(el => el.id.toString() === payer_id.toString()) ||
                condominiaOpts.find(el => el.id.toString() === payer_id.toString())
              }
              onChange={this.handleChangePayer}
              options={payer_type === 'User' ? usersOpts : condominiaOpts}
              cacheOptions={false}
              loadOptions={payer_type === 'User' ? this.loadUsersOptions : this.loadCondominiaOptions}
              isClearable={true}
              shouldTruncate={shouldTruncate}
            />
            <Form.Control.Feedback type="invalid">{errors.payer_type}</Form.Control.Feedback>
          </div>
        </div>
      </>
    )
  }
}

export default CostCenterSelector
