import i18next from 'i18next'
import isEmail from 'validator/lib/isEmail'

const { t } = i18next

const validateFields = data => {
  let requiredFields = ['user_name', 'domain', 'address', 'port', 'authentication', 'from']

  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    if (!data[field]) errors[field] = t('user.user_form.errors.required_field', 'Campo richiesto')
  }
  if (!data['id'] && !data['password']) {
    errors['password'] = t('user.user_form.errors.required_field', 'Campo richiesto')
  }

  if (data['from'] && !isEmail(data.from)) {
    errors.from = t('user.user_form.errors.invalid_email', 'Formato email non valido')
  }
  if (data['port'] && !/^[0-9]{3}$/.test(data.port)) {
    errors.port = errors.port ? errors.port : t('user.user_form.errors.invalid_port', 'Porta SMTP non valida')
  }
  return errors
}

export default validateFields
