import { renderExpireAt } from 'components/system_wide/utils'
import React from 'react'
import { Button, Modal, Form } from 'react-bootstrap'

class SetDateModal extends React.Component {
  state = {
    selected_date: null,
    show_modal: false,
  }

  componentDidMount() {
    this.setState({ selected_date: this.props.selected_date })
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.selected_date)
    this.setState({ show_modal: false })
  }
  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.props.onSubmit(this.state.selected_date)
      this.setState({ show_modal: false })
    }
  }

  handleChange = ({ target }) => {
    let { value } = target
    this.setState({ selected_date: value })
  }

  toggleModal = () => this.setState({ show_modal: !this.state.show_modal })

  render() {
    let { disabled, selected_date: element_date } = this.props
    let { show_modal, selected_date } = this.state

    return (
      <>
        <div className="d-flex align-items-center">
          <Button className="me-3" onClick={this.toggleModal} size="sm" disabled={disabled}>
            <i className="fa fa-edit" />
          </Button>
          {renderExpireAt(element_date)}
        </div>
        {show_modal === true && (
          <Modal show={show_modal}>
            <Modal.Header className="fw-bold">
              <h5>Modifica data</h5>
            </Modal.Header>
            <Modal.Body>
              <Form onKeyDown={this.handleKeyDown}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    type="date"
                    name="selected_date"
                    value={selected_date?.split('T')[0] ?? ''}
                    onChange={this.handleChange}
                    autoFocus={true}
                  />
                </Form.Group>
                <div className="d-flex justify-content-between">
                  <Button variant="secondary" className="rounded-3 mt-2 mb-2" onClick={this.toggleModal}>
                    Chiudi
                  </Button>
                  <Button
                    variant="primary"
                    className="rounded-3 mt-2 mb-2"
                    disabled={!selected_date}
                    onClick={this.handleSubmit}>
                    Salva
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        )}
      </>
    )
  }
}

class SetDate extends React.Component {
  static defaultProps = {
    element: {},
  }

  render() {
    let { id, selected_date, onSubmit, disabled } = this.props
    return <SetDateModal id={id} selected_date={selected_date} onSubmit={onSubmit} disabled={disabled} />
  }
}

export default SetDate
