import React from 'react'
import { findDOMNode } from 'react-dom'
import SimpleSelect, { components } from 'react-select'
import AsyncSelect from 'react-select/async'

const SingleValue = (shouldTruncate, { data, ...props }) => (
  <components.SingleValue {...props}>
    <div className={`d-block ${shouldTruncate === true ? 'text-truncate' : ''}`}>
      {shouldTruncate === true ? data.chipLabel ?? data.label : data.chipLabel ?? data.label}
    </div>
  </components.SingleValue>
)

const selectStyles = {
  control: (styles, state) => {
    return {
      ...styles,
      borderRadius: 'var(--bs-border-radius)',
      ...(state.selectProps.valid === false && {
        borderColor: 'red',
      }),
      minHeight: '2rem',
    }
  },
  menu: styles => ({ ...styles, zIndex: 1000 }),
  menuPortal: styles => ({ ...styles, zIndex: 1000 }),
  valueContainer: styles => ({
    ...styles,
    paddingTop: '0.1rem',
    paddingBottom: '0.1rem',
  }),
  clearIndicator: styles => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
  dropdownIndicator: styles => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
}

const selectTheme = theme => {
  return {
    ...theme,
    borderRadius: 'var(--bs-border-radius)',
    colors: {
      ...theme.colors,
      primary25: '#e9ecef',
      primary: 'var(--bs-primary)',
    },
  }
}

class StyledSelect extends React.Component {
  static defaultProps = {
    styles: selectStyles,
    theme: selectTheme,
    name: '',
    value: '',
    onChange: () => {},
    onMenuClose: () => {},
    onInputChange: () => {},
    options: [],
    isMulti: false,
    isReadOnly: false,
    loadOptions: null,
    isClearable: true,
    shouldTruncate: false,
  }

  state = {
    loading: false,
    myRect: null,
  }
  myRect = null
  freeze = false //mark delay
  timer = null //saved timer

  componentDidMount() {
    this.setState({ myRect: findDOMNode(this) })
  }

  promiseOptions = inputValue => {
    if (inputValue.length >= 3) {
      this.freeze = true
      this.setState({ loading: true })
      return new Promise((res, err) => {
        let p = new Promise((res, err) => {
          if (this.freeze) clearTimeout(this.timer)
          this.timer = setTimeout(async () => {
            this.freeze = false
            this.setState({ loading: false })
            const r = await this.props.loadOptions(inputValue)
            res(r)
          }, 850)
        })

        p.then(function (x) {
          res(x)
        })
      })
    } else {
      return new Promise(resolve => {
        setTimeout(() => {
          this.setState({ loading: false })
          resolve()
        }, 1)
      })
    }
  }

  render() {
    let {
      styles,
      theme,
      name,
      value,
      options,
      isMulti,
      isReadOnly,
      isDisabled,
      valid = true,
      loadOptions,
      onChange,
      onMenuClose,
      onInputChange,
      isClearable,
      shouldTruncate,
      ...rest
    } = this.props

    let menuPlacement = 'auto'
    if (this.props.parentRect) {
      let parentHeight = this.props?.parentRect?.height ?? 0
      let myBottom = this.state.myRect?.getBoundingClientRect()?.bottom ?? 0
      menuPlacement = myBottom + 20 > parentHeight ? 'top' : 'bottom'
    }

    let Select = loadOptions !== null ? AsyncSelect : SimpleSelect
    let cacheOptions = this.props.cacheOptions ?? true

    return (
      <div className="flex-fill" style={{ ...styles }} title={value?.label}>
        <style jsx="true" global="true">{`
          .styled_select__control {
            background-color: #f4f4f4;
          }
          .styled_select__single-value {
            ${isReadOnly === true ? 'color: black' : ''}
            font-size: 16px
          }
          .styled_select__control--is-focused {
            border: solid 1px var(--bs-primary) !important;
            box-shadow: inset 0 1px 1px var(--bs-light), 0 0 8px var(--bs-primary);
          }
          .styled_select__menu {
            z-index: 999999;
            font-size: 16px;
          }
          .styled_select__input {
            font-size: 16px !important;
          }
          .styled_select__menu > div {
            max-height: 10rem;
          }
          .styled_select__indicator > svg > path {
            stroke: var(--bs-primary);
          }
          .styled_select__option,
          .styled_select__single-value {
            white-space: pre-wrap !important;
          }
          .styled_select__indicator {
            ${isReadOnly === true ? 'display:none' : ''}
          }
        `}</style>
        <Select
          classNamePrefix="styled_select"
          styles={{
            ...styles,
            ...{
              control: baseStyles => ({
                ...baseStyles,
                ...(valid ? {} : { borderColor: 'var(--bs-danger)', borderWidth: 2 }),
              }),
            },
          }}
          components={{ SingleValue: props => SingleValue(shouldTruncate, props) }}
          theme={theme}
          id={name}
          isMulti={isMulti}
          instanceId={name}
          name={name}
          value={value}
          onChange={onChange}
          options={options}
          isDisabled={isDisabled}
          onMenuClose={onMenuClose}
          onInputChange={onInputChange}
          menuPlacement={menuPlacement}
          isClearable={isClearable}
          shouldTruncate={shouldTruncate}
          menuShouldScrollIntoView={false}
          noOptionsMessage={() => 'Digita almeno 3 caratteri..'}
          loadingMessage={() => 'Caricamento in corso..'}
          isLoading={this.state.loading}
          {...(loadOptions !== null && { cacheOptions, loadOptions: this.promiseOptions })}
          {...rest}
        />
      </div>
    )
  }
}
export default StyledSelect
