import DownloadContractButton from 'components/system_wide/download_contract_button'
import i18next from 'i18next'
import React from 'react'
import { Button, Card, Col, Container, Modal, Row, Table } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { editCondominium, getCondominium } from 'store/condominia'
import CondominiaMap from './condominia_map'

import StyledSelect from '../system_wide/styled_select'

import config from 'config'
import axios from 'lib/axios'
import jsonapiToData from 'lib/jsonapi_to_data'

const { t } = i18next

class ShowCondominium extends React.Component {
  state = {
    city: '',
    cityOptionLast: [],
    citiesOpts: [],
    editableCity: false,
    labels: {
      city: t('common.form.city', 'Città'),
    },
  }

  zoom = 8
  position = [41.87194, 12.56738]

  async componentDidMount() {
    this.setState({ citiesOpts: [] })
  }

  handleConfirm = async () => {
    const { condominium } = this.props
    const updatedCondominium = {
      ...condominium,
      city_id: this.state.city,
    }
    await this.props.dispatch(editCondominium({ condominium: updatedCondominium }))
    await this.props.dispatch(getCondominium({ condominium_id: condominium.id }))
  }

  isFormValid = () => {
    let { city } = this.state
    return city !== ''
  }

  handleSelectChange =
    () =>
    async (option, { action }) => {
      if (action && action === 'clear') {
        this.setState({ city: '', cityOptionLast: {} })
        return
      }

      let value = option ? option.value ?? '' : ''
      let newValues = {}

      if (value !== '') {
        newValues = {
          city: value,
          cityOptionLast: { value: value, label: option.label },
        }
      }

      this.setState(newValues)
    }

  loadCitiesOptions = async inputValue => {
    if (inputValue.length >= 3) {
      let url = `${config.SERVER_API_URL}/v1/cities?filter[q]=${encodeURIComponent(inputValue)}&include=province`
      let response = await axios({ url, method: 'get' })
      let users_data = jsonapiToData(response.data)
      let citiesOpts = users_data.data.map(r => ({
        value: r.id,
        label: r.name + ` (${r.province.acronym})`,
        province: r.province.name,
      }))

      if (this.state.cityOptionLast.label && !citiesOpts.some(opt => opt.value === this.state.cityOptionLast.value)) {
        citiesOpts.push(this.state.cityOptionLast)
      }

      this.setState({ citiesOpts: citiesOpts })
      return citiesOpts
    } else return []
  }

  render() {
    let { labels, citiesOpts, city, editableCity } = this.state
    let { condominium, tServices, isAdmin } = this.props

    if (condominium.id && condominium.lat && condominium.lon) {
      this.position = [Number(condominium.lat), Number(condominium.lon)]
    }
    return (
      <Modal backdrop="static" centered show={true} onHide={this.props.onCloseModal} size="xl">
        <Modal.Header className="pb-0" closeButton>
          <Modal.Title>
            <h4 className="text-primary p-1">
              <Trans i18nKey="condominia.show_condominium.title">Dettagli condominio</Trans>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col sm={8} className="d-flex">
                <Card className="flex-fill">
                  <Card.Header>Info condominio</Card.Header>
                  <Card.Body>
                    <Card.Title>
                      <span className="text-muted">Nome:</span> {condominium?.name}
                    </Card.Title>
                    <Card.Text>
                      <Table className="table-padded mt-4">
                        <tbody>
                          <tr>
                            <td className="text-muted">Indirizzo:</td>
                            <td>{condominium?.street}</td>
                          </tr>
                          <tr>
                            <td className="text-muted">Città:</td>
                            <td>
                              {condominium?.city ? (
                                condominium?.city?.name + ' (' + condominium?.city?.province?.name + ')'
                              ) : (
                                <>
                                  {editableCity ? (
                                    <Container className="p-2">
                                      <Row>
                                        <Col className="col-8 p-0">
                                          <StyledSelect
                                            placeholder={labels.city}
                                            value={citiesOpts.find(o => parseInt(o.value) === parseInt(city))}
                                            onChange={this.handleSelectChange()}
                                            options={citiesOpts}
                                            loadOptions={this.loadCitiesOptions}
                                          />
                                        </Col>
                                        <Col className="col-4 p-0">
                                          <Button
                                            onClick={this.handleConfirm}
                                            variant="primary"
                                            disabled={!this.isFormValid()}
                                            size="xs"
                                            className="ms-2">
                                            Salva
                                          </Button>
                                          <Button
                                            onClick={() => this.setState({ editableCity: false })}
                                            variant="secondary"
                                            size="xs"
                                            className="ms-2">
                                            X
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Container>
                                  ) : (
                                    <Button
                                      variant="primary"
                                      size="xs"
                                      onClick={() => this.setState({ editableCity: true })}>
                                      Inserisci città
                                    </Button>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-muted">CAP</td>
                            <td>{condominium?.cap}</td>
                          </tr>
                          {condominium?.city && (
                            <tr>
                              <td className="text-muted">Regione:</td>
                              <td>{condominium?.city?.province?.region?.name}</td>
                            </tr>
                          )}
                          <tr>
                            <td className="text-muted">Codice fiscale:</td>
                            <td>{condominium?.fiscal_code}</td>
                          </tr>
                          <tr>
                            <td className="text-muted">IBAN:</td>
                            <td>{condominium?.iban ?? '...'}</td>
                          </tr>
                          <tr>
                            <td className="text-muted">SDI:</td>
                            <td>{condominium?.sdi ?? '...'}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} className="d-flex">
                <Card className="flex-fill">
                  <Card.Header>Mappa</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <CondominiaMap
                        condominia={[condominium]}
                        zoom={this.zoom}
                        position={this.position}
                        handleSelectedCondominia={() => {}}
                        style={{ height: '19rem' }}
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col sm={8}>
                <Card>
                  <Card.Header>Servizi attivi</Card.Header>
                  <Card.Body>
                    <Card.Text className="d-flex flex-row">
                      {condominium?.services?.map((s, i) => (
                        <div key={i}>
                          <i
                            className={`${s.icon_classes} p-3`}
                            title={tServices?.[s.codename] ?? s.description}
                            style={{ fontSize: '1.73rem' }}
                          />
                        </div>
                      ))}
                      {(condominium?.contracts?.length ?? 0) === 0 && (
                        <div className="text-muted">Nessun servizio attivo</div>
                      )}
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card className="mt-2">
                  <Card.Header>Documentazione</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Table responsive bordered hover size="sm">
                        <thead>
                          <tr>
                            {isAdmin && <th>ID</th>}
                            <th>Servizio</th>
                            <th>File</th>
                            <th className="text-center" style={{ width: '2rem' }}>
                              Scarica
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {condominium?.contracts?.map(ctr => {
                            let service = condominium?.services?.find(
                              s =>
                                s.id.toString() === ctr?.service_id?.toString() ||
                                s.id.toString() === ctr?.service?.id?.toString()
                            )
                            return ctr?.attachments?.map(at => {
                              return (
                                <tr key={ctr.id} style={{ height: '3rem', verticalAlign: 'middle' }}>
                                  {isAdmin && <td style={{ width: '1.2rem' }}>{ctr.id}</td>}
                                  <td>{tServices?.[service?.codename] ?? service?.description}</td>
                                  <td style={{ maxWidth: '14rem', overflowWrap: 'break-word' }}>{at.name}</td>
                                  <td>
                                    <DownloadContractButton
                                      contractId={ctr.id}
                                      attachmentsId={at.id}
                                      fileName={at.name}
                                    />
                                  </td>
                                </tr>
                              )
                            })
                          })}
                        </tbody>
                      </Table>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} className="d-flex">
                <Card className="flex-fill">
                  <Card.Header>
                    Amministratore
                    {condominium?.user?.deactivated === true && (
                      <i className="fa fa-ban ms-3 text-warning " aria-hidden="true" title="Disabilitato" />
                    )}
                    {!condominium?.user && (
                      <span style={{ marginLeft: 10, color: 'orange', fontWeight: 'bold' }}>CEDUTO</span>
                    )}
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <div style={{ fontSize: '0.8rem' }} className="admin-table">
                        <div>
                          <div className="d-flex border-bottom p-2">
                            <div className="text-muted pe-1" style={{ minWidth: '30%' }}>
                              Ragione sociale:
                            </div>
                            <div>{condominium?.user?.tax_datum?.business_name}</div>
                          </div>
                          <div className="d-flex border-bottom p-2">
                            <div className="text-muted pe-1" style={{ minWidth: '30%' }}>
                              Nome:
                            </div>
                            <div>{condominium?.user?.name}</div>
                          </div>
                          <div className="d-flex border-bottom p-2">
                            <div className="text-muted pe-1" style={{ minWidth: '30%' }}>
                              Cognome:
                            </div>
                            <div>{condominium?.user?.surname}</div>
                          </div>
                          <div className="d-flex border-bottom p-2">
                            <div className="text-muted pe-1" style={{ minWidth: '30%' }}>
                              Email:
                            </div>
                            <div className="text-wrap-ow" style={{ maxWidth: '16rem' }}>
                              {condominium?.user?.email}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onCloseModal} variant="secondary">
            <Trans i18nKey="common.close_button">Chiudi</Trans>
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default connect()(ShowCondominium)
