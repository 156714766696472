import ActionsDropdownSplitted from 'components/system_wide/actions_dropdown_splitted'
import CheckboxFilter from 'components/system_wide/checkbox_filter'
import CopyToClipboard from 'components/system_wide/copy_to_clipboard'
import ModalSettings from 'components/system_wide/modal-settings'
import ReqResetPasswordModal from 'components/system_wide/req_reset_password_modal'
import ResponsiveTable from 'components/system_wide/responsive_table'
import SearchBox from 'components/system_wide/search_box'
import UserForm from 'components/users/form'
import WithCapabilities from 'enhancers/with_capabilities'
import i18next from 'i18next'
import { isAdmin, isCondominiaAdmin, isRecapitista } from 'lib/utils'
import React from 'react'
import { Button, Dropdown, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { getMyContracts } from 'store/contracts'
import {
  activeUser,
  createUser,
  deleteUser,
  filterRoles,
  getRoles,
  getUsers,
  resetCurrentUser,
  resetPasswordUser,
  setCurrentUser,
  toggleDeactivatedFilter,
  toggleShowForm,
  updateUser,
} from 'store/users'
import ShowUserModal from './show_user'

const { t } = i18next

const mstp = state => {
  let { users, total, roles, show_form, current_user, errors, filtered_roles } = state.usersData
  let { my_contracts } = state.contracts
  let { strings: trans } = state.translations
  let { id: own_user_id, role_id: own_user_role } = state.userInfo
  return {
    users,
    total,
    roles,
    show_form,
    current_user,
    errors,
    own_user_id,
    own_user_role,
    filtered_roles,
    trans,
    my_contracts,
    isAdmin: isAdmin(own_user_role, roles),
    isCondominiaAdmin: isCondominiaAdmin(own_user_role, roles),
    isRecapitista: isRecapitista(own_user_role, roles),
  }
}

class UsersList extends React.Component {
  static defaultProps = {
    users: [],
    getData: () => {},
    columns: [],
    filter: null,
  }

  state = {
    show_modal_deactive: false,
    id_to_delete: null,
    user_to_deactive: null,
    show_modal_active: false,
    id_to_active: null,
    user_to_active: null,
    show_modal_reset_password: false,
    email_to_reset: null,
    user_to_reset_password: null,
    filter: '',
    show_user_modal: false,

    type_selected: 'single',
    selected_users: [],
    loading: false,
    progress: 0,
  }

  tableRef = null
  bulk_actions = {
    to_review: t('actions_dropdown.to_review', 'Da revisionare'),
  }

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  onOpenViewUser = async id => {
    await this.props.dispatch(setCurrentUser(id))
    await this.props.dispatch(getMyContracts({ user_id: id }))
    this.toggleShowUserModal()
  }

  canCRUD = role_id => {
    let { own_user_role, roles } = this.props
    let role_permissions = {
      admin: () => true,
      technician: role => !['admin', 'technician', 'delivery_person'].includes(role),
      delivery_person: role => !['admin', 'technician', 'delivery_person'].includes(role),
      building_administrator: role => role === 'subaccount',
    }

    let ownerRole = roles[own_user_role]
    let crudRole = roles[role_id]

    return role_permissions[ownerRole] ? role_permissions[ownerRole](crudRole) : false
  }

  getActions = rowData => {
    return [
      {
        icon: 'fa-eye',
        text: t('actions_dropdown.show_item', 'Visualizza'),
        onClick: async ({ id }) => this.onOpenViewUser(id),
      },
      ...(!this.props.isCondominiaAdmin
        ? [
            {
              icon: 'fa-gear',
              text: t('actions_dropdown.edit_item', 'Settings'),
              disabled: rowData.id === this.props.own_user_id,
              onClick: ({ id }) => {
                this.props.dispatch(setCurrentUser(id))
                this.props.dispatch(this.toggleShowModalSettings())
              },
            },
          ]
        : []),
      ...(this.canCRUD(rowData.role?.id)
        ? [
            {
              icon: 'fa-edit',
              text: t('actions_dropdown.edit_item', 'Modifica'),
              disabled: rowData.id === this.props.own_user_id,
              onClick: ({ id }) => {
                this.props.dispatch(setCurrentUser(id))
                this.props.dispatch(toggleShowForm())
              },
            },
            {
              icon: 'fa-ban',
              text: t('actions_dropdown.deactive_item', 'Disattiva'),
              visible: !rowData.deactivated,
              disabled: rowData.id === this.props.own_user_id,
              onClick: ({ id, surname, name }) => {
                this.setState({
                  show_modal_deactive: true,
                  id_to_delete: id,
                  user_to_deactive: `${surname} ${name}`,
                })
              },
            },
            {
              icon: 'fa-check',
              text: t('actions_dropdown.activate_item', 'Riattiva'),
              visible: rowData.deactivated,
              disabled: rowData.id === this.props.own_user_id,
              onClick: ({ id, surname, name }) => {
                this.setState({
                  show_modal_active: true,
                  id_to_active: id,
                  user_to_active: `${surname} ${name}`,
                })
              },
            },
            {
              icon: 'fa-key',
              text: t('actions_dropdown.reset_password', 'Reset password'),
              disabled: rowData.id === this.props.own_user_id,
              onClick: ({ email, name, surname }) => {
                this.setState({
                  show_modal_reset_password: true,
                  email_to_reset: email,
                  user_to_reset_password: `${surname} ${name}`,
                })
              },
            },
          ]
        : []),
    ]
  }

  toggleShowUserModal = () => {
    if (this.state.show_user_modal === true) {
      this.props.dispatch(resetCurrentUser())
    }
    this.setState({ show_user_modal: !this.state.show_user_modal })
  }
  toggleShowModalSettings = () => {
    if (this.state.show_modal_settings === true) {
      this.props.dispatch(resetCurrentUser())
    }
    this.setState({ show_modal_settings: !this.state.show_modal_settings })
  }

  actionsRenderer = ({ rowData }) => {
    return (
      <ActionsDropdownSplitted
        rowData={rowData}
        actions={this.getActions(rowData)}
        onClickMaster={() => this.onOpenViewUser(rowData.id)}
      />
    )
  }

  roleRenderer = ({ rowData: { role } }) => {
    let userRole = this.props.roles[role.id] ?? null
    if (!userRole) return null
    let tRoles = this.props.trans?.tables?.roles
    return tRoles?.[userRole] ?? userRole.replace('_', ' ')
  }

  renderIdColumn = () => {
    if (!this.props.isCondominiaAdmin) {
      return [
        {
          title: 'Id',
          className: 'd-lg-block flex-fill',
          style: { width: '3%', minWidth: '4rem' },
          sortable: false,
          data: 'id',
        },
      ]
    }
    return []
  }

  toogleAllUser = () => {
    let { selected_users } = this.state
    let { users } = this.props
    let all_selected = users.every(u => selected_users.includes(u.id))
    let current_users = []
    if (all_selected) {
      current_users = selected_users.filter(su => !users.find(u => u.id.toString() === su))
    } else {
      current_users = new Set([...selected_users, ...users.map(e => e.id.toString())])
    }
    this.setState({ selected_users: [...current_users] })
  }

  toogleUser = ({ target: { value } }) => {
    let { selected_users } = this.state
    if (selected_users.includes(value)) {
      this.setState({ selected_users: selected_users.filter(e => e !== value) })
    } else {
      this.setState({ selected_users: [...selected_users, value] })
    }
  }

  selectCheckboxRender = ({ rowData }) => {
    let { selected_users } = this.state

    return (
      <Form.Check
        variant="primary"
        type="checkbox"
        className="py-1"
        value={rowData.id}
        checked={selected_users.includes(rowData.id.toString())}
        onChange={this.toogleUser}
      />
    )
  }

  handleSelection = () => {
    this.setState(
      {
        type_selected: this.state.type_selected === 'single' ? 'multiple' : 'single',
        selected_users: this.state.type_selected === 'single' ? [] : this.state.selected_users,
      },
      () => {
        this.tableRef?.current?.refreshData()
      }
    )
  }

  renderCheckbox = () => {
    let { selected_users, type_selected } = this.state
    let { users } = this.props
    if (type_selected === 'single') return ''
    let all_selected = users.every(u => selected_users.includes(u.id.toString()))
    return (
      <Button onClick={this.toogleAllUser} variant="primary" className="no-padding-button">
        <i className={`far ${all_selected ? 'fa-check-square' : 'fa-square'}`} style={{ fontSize: '1.5rem' }}></i>
      </Button>
    )
  }
  getColumn = () => {
    return [
      {
        title: this.renderCheckbox(),
        className: 'd-lg-block text-center',
        style: { width: '9%', minWidth: '4rem' },
        data: rowData => {
          return this.state.type_selected === 'single'
            ? this.actionsRenderer(rowData)
            : this.selectCheckboxRender(rowData)
        },
      },
      ...this.renderIdColumn(),
      {
        title: t('users.list.name', 'Nome'),
        className: 'd-lg-block flex-fill',
        style: { width: '10%', minWidth: '8rem' },
        sortable: true,
        data: 'name',
      },
      {
        title: t('users.list.surname', 'Cognome'),
        className: 'd-lg-block flex-fill',
        style: { width: '10%', minWidth: '8rem' },
        sortable: true,
        data: 'surname',
      },
      {
        title: t('users.list.business_name', 'Ragione sociale'),
        className: 'd-lg-block flex-fill',
        style: { width: '16%', minWidth: '9rem' },
        sortable: true,
        sortingKey: 'business_name',
        data: ({ rowData }) => <div>{rowData?.tax_datum?.business_name ?? ''}</div>,
      },
      {
        title: t('users.list.role', 'Ruolo'),
        className: 'd-none d-lg-block flex-fill text-capitalize text-truncate',
        style: { width: '20%', minWidth: '10rem' },
        sortable: true,
        data: this.roleRenderer,
      },
      {
        title: t('users.list.email', 'Email'),
        className: 'd-lg-block flex-fill text-truncate',
        style: { width: '14%', minWidth: '15rem' },
        data: ({ rowData }) => <CopyToClipboard value={rowData?.email} />,
      },
      {
        title: t('users.list.phone', 'Telefono'),
        className: 'd-none d-lg-block flex-fill',
        style: { width: '10%', minWidth: '10rem' },
        data: ({ rowData }) => <div>{rowData?.tax_datum?.phone ?? ''}</div>,
      },
      {
        title: t('users.list.vat_number', 'P.I.'),
        className: 'd-none d-lg-block',
        style: { width: '10%', minWidth: '10rem' },
        data: ({ rowData }) => <div>{rowData?.tax_datum?.vat_number ?? ''}</div>,
      },
    ]
  }

  getData = async (index = 0, chunkSize, sortData, filter) => {
    if (Object.keys(this.props.roles).length === 0) await this.props.dispatch(getRoles())
    await this.props.dispatch(getUsers({ offset: index, limit: chunkSize, sortData, filter }))
  }

  onToggleForm = async () => {
    if (this.props.show_form === true) {
      this.props.dispatch(resetCurrentUser())
      if (this.state.read_only === true) {
        this.setState({ read_only: false })
      }
    }
    this.props.dispatch(toggleShowForm())
  }

  onCancelActive = () => {
    this.setState({
      show_modal_active: false,
      id_to_active: '',
      user_to_active: '',
    })
  }
  onCancelDeactive = () => {
    this.setState({
      show_modal_deactive: false,
      id_to_delete: '',
      user_to_deactive: '',
    })
  }
  onCancelResetPassword = () => {
    this.setState({
      show_modal_reset_password: false,
      email_to_reset: '',
      user_to_reset_password: '',
    })
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({ [name]: value }, () => this.updateHash())
  }

  onFilterChange = filter => {
    this.setState({ filter }, () => {
      if (filter === '') this.tableRef.current.refreshData()
    })
  }
  onChangeFilterDeactivated = () => {
    this.props.dispatch(toggleDeactivatedFilter())
  }

  saveUser = async user => {
    if (user.id !== null) {
      await this.props.dispatch(updateUser(user))
      this.tableRef.current.refreshData()
    } else {
      await this.props.dispatch(createUser(user))
      this.tableRef.current.refreshData()
    }
    if (this.state.read_only === true) {
      this.setState({ read_only: false })
    }
  }
  onConfirmDeactive = async () => {
    let id = this.state.id_to_delete
    await this.props.dispatch(deleteUser(id))
    this.setState({
      show_modal_deactive: false,
      id_to_delete: '',
      user_to_deactive: '',
    })
    this.tableRef.current.refreshData()
  }
  onConfirmActive = async () => {
    let id = this.state.id_to_active
    await this.props.dispatch(activeUser(id))
    this.setState({
      show_modal_active: false,
      id_to_active: '',
      user_to_active: '',
    })
    this.tableRef.current.refreshData()
  }
  onConfirmResetPassword = async () => {
    let email = this.state.email_to_reset
    await this.props.dispatch(resetPasswordUser(email))
    this.setState({
      show_modal_reset_password: false,
      email_to_reset: '',
      user_to_reset_password: '',
    })
  }

  rowClasses = ({ deactivated }) => {
    return deactivated === true ? 'bg-warning bg-gradient bg-opacity-50' : ''
  }
  onFilterRoles = roles => {
    this.props.dispatch(filterRoles(roles))
  }
  handleSelectType = value => () => {
    this.setState({ activeTab: value, selected_contracts: [] })
  }

  handleSearch = () => {
    this.tableRef.current.refreshData()
  }

  render() {
    let {
      users,
      total,
      current_user,
      show_form,
      is_deactivated,
      errors,
      roles,
      filtered_roles,
      trans,
      my_contracts,
      isCondominiaAdmin,
    } = this.props
    let { selected_users, type_selected } = this.state
    if (!users) return null
    let tRoles = trans?.tables?.roles
    let rolesOptions = Object.entries(roles).map(([k, v]) => ({
      id: k,
      name: tRoles?.[v] ?? v.replace('_', ' ').toUpperCase(),
    }))

    let columns = this.getColumn()

    return (
      <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3 pe-lg-3 pt-2 zi-1">
        <div className="d-flex flex-column flex-fill">
          <div className="container-fluid px-0 mb-2">
            <div className="d-flex flex-wrap flex-fill">
              <div className="d-flex flex-wrap flex-fill gap-2 align-items-center">
                <WithCapabilities isAdmin={true}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`tooltip-multiselect`}>
                        <Trans i18nKey="contracts.enable_multiselect_label">Abilita/Disabilita Selezione</Trans>
                      </Tooltip>
                    }>
                    <Button
                      onClick={this.handleSelection}
                      className="d-none me-1 rounded-0 no-shadow-button"
                      variant="primary">
                      <i className="fa-solid fa-list-check me-1"></i>
                    </Button>
                  </OverlayTrigger>
                </WithCapabilities>
                {type_selected === 'multiple' && (
                  <Dropdown className="me-2 action-menu-dropdown">
                    <Dropdown.Toggle
                      variant="light"
                      id="multi-selected-menu"
                      className="rounded-0 action-menu-dropdown">
                      <Trans i18nKey="contracts.bulk_action_label">Azioni massive</Trans>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {Object.keys(this.bulk_actions).map(a => {
                        return (
                          <Dropdown.Item key={a} disabled={selected_users.length === 0} onClick={a => {}}>
                            {this.bulk_actions[a]}
                          </Dropdown.Item>
                        )
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                <Button onClick={this.onToggleForm} disabled={this.props.show_form}>
                  <i className="fas fa-plus fa-fw text-start" />
                  <Trans i18nKey="users.list.add_button">Aggiungi utente</Trans>
                </Button>
                <Form.Check
                  className="ms-auto me-2"
                  type="switch"
                  id="deactivated-switch"
                  label="Visualizza disabilitati"
                  value={is_deactivated}
                  onChange={this.onChangeFilterDeactivated}
                />
                <CheckboxFilter
                  className=""
                  label={t('users.filtered_roles', 'Ruoli')}
                  items={rolesOptions}
                  filteredItems={filtered_roles}
                  onChangeFilter={this.onFilterRoles}
                />
                <SearchBox
                  style={{ width: '14rem' }}
                  value={this.state.filter}
                  onChange={this.onFilterChange}
                  onEnter={this.handleSearch}
                  disabled={this.props.show_form}
                />
                <Button onClick={this.handleSearch} variant="secondary">
                  <i className="fa fa-search me-2" />
                  <Trans i18nKey="common.cancel_button">Cerca</Trans>
                </Button>
              </div>
            </div>
          </div>
          <ResponsiveTable
            ref={this.tableRef}
            className="flex-fill border"
            rowClasses={this.rowClasses}
            menuWidth={isCondominiaAdmin === true ? '50rem' : '82rem'}
            data={users}
            getData={this.getData}
            totalRecords={total}
            columns={columns}
            showMenu={show_form}
            menuContent={UserForm}
            menuContentProps={{
              user: current_user,
              roles: this.props.roles,
              tRoles,
              closeForm: this.onToggleForm,
              onSave: this.saveUser,
              errors,
              dispatch: this.props.dispatch,
              readOnly: this.state.read_only,
              isCondominiaAdmin,
            }}
            filter={this.state.filter}
          />
        </div>
        <Modal backdrop="static" centered show={this.state.show_modal_deactive} onHide={this.onCancelDeactive}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Trans i18nKey="users.list.deactive.modal_title">Disattiva utente</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <Trans
                i18nKey="users.list.deactive.modal_msg"
                values={{ user_name: this.state.user_to_deactive }}
                defaults="Per procedere alla disattivazione di <1>{{ user_name }}</1> click su Disattiva"
                components={{ 1: <b /> }}
              />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelDeactive} variant="secondary" className="me-auto">
              <Trans i18nKey="common.cancel_button">Annulla</Trans>
            </Button>
            <Button onClick={this.onConfirmDeactive} variant="danger" disabled={false /*this.props.is_pending*/}>
              <Trans i18nKey="common.deactive_button">Disattiva</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal backdrop="static" centered show={this.state.show_modal_active} onHide={this.onCancelActive}>
          <Modal.Header closeButton>
            <Modal.Title>
              <Trans i18nKey="users.list.active.modal_title">Attiva utente</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              <Trans
                i18nKey="users.list.active.modal_msg"
                values={{ user_name: this.state.user_to_active }}
                defaults="Per procedere ad attivare <1>{{ user_name }}</1> click su Attiva"
                components={{ 1: <b /> }}
              />
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.onCancelActive} variant="secondary" className="me-auto">
              <Trans i18nKey="common.cancel_button">Annulla</Trans>
            </Button>
            <Button onClick={this.onConfirmActive} variant="success" disabled={false /*this.props.is_pending*/}>
              <Trans i18nKey="common.deactive_button">Attiva</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
        <ReqResetPasswordModal
          onCancelResetPassword={this.onCancelResetPassword}
          onConfirmResetPassword={this.onConfirmResetPassword}
          show={this.state.show_modal_reset_password}
          user_to_reset_password={this.state.user_to_reset_password}
          user_profile={false}
        />
        {this.state.show_user_modal && (
          <ShowUserModal
            show={this.state.show_user_modal}
            user={current_user}
            trans={trans}
            contracts={my_contracts}
            roles={roles}
            tRoles={tRoles}
            onCloseModal={this.toggleShowUserModal}
          />
        )}
        {this.state.show_modal_settings && (
          <ModalSettings user_id={current_user.id} show={true} onCloseModal={this.toggleShowModalSettings} />
        )}
      </div>
    )
  }
}

export default connect(mstp)(UsersList)
