import React from 'react'
import { connect } from 'react-redux'
import { Button, Spinner } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import CondominiaMap from './condominia_map'
import {
  getGeoDataCondominium,
  getCondominium,
  getAdministrators,
  setAdministratorCondominia,
  getCondominiumContracts,
  resetCurrentCondominium,
  toggleShowAddCondominia,
} from 'store/condominia'
import AddCondominia from 'components/condominia/add_condominia_modal'
import { getCity } from 'store/common'

const mstp = state => {
  let { geo_condominia, edit_condominia, total, loading, current_page, pages } = state.condominia
  let { services } = state.services
  let { id: user_id, role_id } = state.userInfo
  let { strings: trans } = state.translations
  let tServices = trans?.tables?.services ?? {}
  return {
    services,
    geo_condominia,
    edit_condominia,
    total,
    user_id,
    role_id,
    trans,
    tServices,
    loading,
    current_page,
    pages,
  }
}

class CondominiaListMaps extends React.Component {
  static defaultProps = {
    reset_filter: false,
  }

  componentDidMount = async () => {
    await this.props.dispatch(getAdministrators())
    await this.props.dispatch(getGeoDataCondominium())
    if (this.props?.edit_condominia?.city_id && isNaN(this.props?.edit_condominia?.city_id)) {
      this.mappingMunicipaly(this.props.edit_condominia.city_id)
    }
  }

  state = {
    city: '',
    province: '',
    region: '',

    hide_columns: true,
    fixed: false,
    scrollX: 0,
    filter: '',
    reset_map: false,
  }

  zoom = 6
  position = [41.87194, 12.56738]

  componentDidUpdate(prevProps) {
    if (prevProps.edit_condominia?.id !== this.props.edit_condominia?.id && this.props.edit_condominia?.city_id) {
      this.mappingMunicipaly(this.props.edit_condominia.city_id)
    }
  }

  mappingMunicipaly = async value => {
    let result = await this.props.dispatch(getCity(value))
    let { name: city = '', province = '', region = '' } = result?.payload ?? {}
    this.setState({ city, province, region })
  }

  handleSelectedCondominia = async condominium_id => {
    await this.props.dispatch(getCondominium({ condominium_id }))
    await this.props.dispatch(setAdministratorCondominia())
    await this.props.dispatch(getCondominiumContracts(condominium_id))
    this.setState({ reset_map: false })
  }

  handleResetSelected = async () => {
    await this.props.dispatch(resetCurrentCondominium())
    this.setState({ reset_map: true })
  }

  onHandleEdit = condominium_id => async () => {
    await this.props.dispatch(getCondominium({ condominium_id }))
    this.props.dispatch(toggleShowAddCondominia())
  }

  render() {
    let { edit_condominia, geo_condominia, tServices, loading } = this.props
    let { city, province, region, reset_map } = this.state
    let reload_map = (reset_map || this.props.reset_filter) && !(edit_condominia?.id ?? false)

    let building_administrator =
      edit_condominia?.user?.tax_datum?.business_name ??
      `${edit_condominia?.user?.name} ${edit_condominia?.user?.surname}`
    return (
      <>
        <div className="container-fluid ps-0 overflow-auto border" style={{ height: '95%' }}>
          <div className="row bg-white">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <CondominiaMap
                condominia={geo_condominia}
                handleSelectedCondominia={this.handleSelectedCondominia}
                resetSelection={reload_map}
              />
            </div>
            <div className="mt-3 col-lg-4 col-md-4 col-sm-12">
              {!edit_condominia?.id && (
                <Trans i18nKey="condominia.maps.not_selected">Seleziona un condominio per visualizzare i dati</Trans>
              )}
              {edit_condominia?.id && (
                <div className="row">
                  <div className="col-12 mt-3">
                    <h4>
                      {edit_condominia.name}{' '}
                      <i
                        className="fa fa-close float-end"
                        onClick={this.handleResetSelected}
                        style={{ cursor: 'pointer' }}></i>
                    </h4>
                  </div>
                  <div className="col-12">
                    <p className="mb-5">
                      {edit_condominia.street}, {city}, {edit_condominia.cap} {province}, {region}{' '}
                    </p>
                    <p className="mb-3">
                      <b>
                        <Trans i18nKey="condominia_maps.fiscal_code"> Codice fiscale</Trans>:{' '}
                      </b>
                      {edit_condominia?.fiscal_code ?? ''}
                    </p>
                    <p className="mb-3">
                      <b>Iban:</b>
                      {edit_condominia?.iban ?? ''}
                    </p>
                    <p className="mb-3">
                      <b>
                        {' '}
                        <Trans i18nKey="condominia_maps.administrator">Amministratore</Trans>:
                      </b>
                    </p>

                    <div className="d-flex flex-row align-items-center mb-3 ms-3">
                      {edit_condominia?.user.deactivated === true && (
                        <i className="fa fa-ban me-3 text-warning " aria-hidden="true" title="Disabilitato" />
                      )}
                      <div>{building_administrator}</div>
                    </div>

                    <p className="mb-2">
                      <b>
                        {' '}
                        <Trans i18nKey="condominia_maps.services">Servizi</Trans>:
                      </b>
                    </p>
                    <div className="row ms-1">
                      {(edit_condominia?.services ?? []).length === 0 && (
                        <p>
                          <Trans i18nKey="condominia_maps.empty_service">Nessun servizio associato</Trans>
                        </p>
                      )}

                      {(edit_condominia?.services ?? []).map(s => {
                        return (
                          <div key={s.id} className="col-md-6 mb-2 text-wrap d-flex align-self-center">
                            <div className="d-flex flex-row align-items-center">
                              <i className={s.icon_classes}></i>
                              <div className="ms-2">{tServices?.[s.codename] ?? s.description}</div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div className="d-flex flex-row mt-5 ">
                      <Button className="me-4" onClick={this.onHandleEdit(edit_condominia.id)}>
                        <Trans i18nKey="common.edit_button"> Modifica</Trans>
                      </Button>
                      <Button onClick={() => this.props.onShowCondominium(edit_condominia.id)}>
                        <Trans i18nKey="common.more_info_button"> Mostra dettagli</Trans>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AddCondominia mountOnMap={true} />
        {loading && (
          <div
            className="d-flex w-100 vh-94 justify-content-center"
            style={{ position: 'absolute', backgroundColor: '#cfcfcf90', zIndex: '99999' }}>
            <div className="w-100 m-auto text-center">
              <div className="loader-box">
                <Spinner animation="border" role="status"></Spinner>
                <div>Loading...</div>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect(mstp)(CondominiaListMaps)
