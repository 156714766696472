import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap'

class RecipientsTable extends React.PureComponent {
  static defaultProps = {
    openEditRecipient: () => {},
  }

  render() {
    let { recipients, enable_edit } = this.props
    return (
      <>
        <Table striped bordered hover size="sm">
          <thead style={{ position: 'sticky', top: 0, background: 'white', width: '101%' }}>
            <tr>
              {enable_edit && <th className="border"></th>}
              <th className="border">Soggetto</th>
              <th className="border">Indirizzo</th>
              <th className="border">Email</th>
              <th className="border">Pec</th>
            </tr>
          </thead>
          <tbody style={{ maxHeight: 470, overflow: 'auto' }}>
            {recipients.map(e => {
              return (
                <tr key={`r-${e.id}`} style={{ ...(enable_edit ? { height: 'unset' } : {}) }}>
                  {enable_edit && (
                    <td style={{ maxWidth: '1.5rem', alignItems: 'center' }}>
                      <Button size="sm" className="p-1" variant="secondary" onClick={this.props.openEditRecipient(e)}>
                        <i className="fa fa-pencil" />
                      </Button>
                    </td>
                  )}
                  <td>{e.name}</td>
                  {e.name !== 'TEMPLATE' ? (
                    <td>
                      {e?.address?.street ?? e?.street ?? ''} {(e?.address?.street || e?.street) && ' - '}{' '}
                      {e?.address?.cap ?? ''} {e?.address?.city?.name ?? ''}
                      {e?.address?.city?.province?.acronym ? ' (' + e?.address?.city?.province?.acronym + ')' : ''}
                      {e?.address?.street ? '' : <i className="fa fa-xmark text-primary" />}
                    </td>
                  ) : (
                    <td>{e?.address?.street ?? e?.street}</td>
                  )}
                  <td className="text-break">{e?.email ?? <i className="fa fa-xmark text-primary" />}</td>
                  <td className="text-break">{e?.pec ?? <i className="fa fa-xmark text-primary" />}</td>
                </tr>
              )
            })}
            {recipients.length === 0 && (
              <tr>
                <td colSpan="7" className="text-center pt-3 w-100">
                  Nessun elemento trovato
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </>
    )
  }
}

export class ModalRecipientsTable extends React.PureComponent {
  state = { show: false }

  toggleModal = () => this.setState({ show: !this.state.show })

  render() {
    let { show } = this.state
    let { attachment, recipients } = this.props
    let selected_recipients = attachment.attachment_options?.[0]?.recipient_ids ?? recipients.map(r => parseInt(r.id))
    let destinations = recipients.filter(r => selected_recipients.includes(parseInt(r.id)))
    return (
      <>
        <Modal show={show} size="xl">
          <Modal.Header>
            <h5>Lista destinatari</h5>
          </Modal.Header>
          <Modal.Body>
            <RecipientsTable recipients={destinations} />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="secondary" onClick={this.toggleModal}>
              <i className={`fa fa-times`} /> Chiudi
              <div />
            </Button>
          </Modal.Footer>
        </Modal>
        <div onClick={this.toggleModal}>
          <i className="fa fa-users text-primary" /> {selected_recipients.length}
        </div>
      </>
    )
  }
}

export default RecipientsTable
