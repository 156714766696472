import i18next from 'i18next'
import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { connect } from 'react-redux'

import styles from './select_municipality.module.css'
import StyledSelect from './styled_select'

import config from 'config'
import axios from 'lib/axios'
import jsonapiToData from 'lib/jsonapi_to_data'

const { t } = i18next

const capRegExp = /^[0-9]*$/

const mstp = state => {
  let { cities } = state.common

  return {
    cities,
  }
}

class SelectMunicipality extends React.Component {
  static defaultProps = {
    errors: [],
    city: '',
    cityData: {},
    codiceIstat: null,
    cap: '',
    labels: {},
    showRequiredMarks: false,
    hasCap: true,
    isReadOnly: false,
    onMunicipalityChange: () => {},
    disabled: false,
  }

  state = {
    cityOptionLast: [],
    citiesOpts: [],
    labels: {
      city: t('common.form.city', 'Città'),
      cap: t('common.form.cap', 'CAP'),
    },
  }

  handleSelectChange =
    () =>
    async (option, { action }) => {
      if (action && action === 'clear') {
        this.setState({ city: '', cityOptionLast: {} })
        this.props.onChange({
          city_id: '',
        })
        return
      }

      let value = option ? option.value ?? '' : ''
      let newValues = {}

      if (value !== '') {
        newValues = {
          city: value,
          cityOptionLast: { value: value, label: option.label },
        }
      }

      this.setState(newValues)
      this.props.onChange({
        city_id: newValues.city,
      })
    }

  async componentDidMount() {
    this.setState({ citiesOpts: [] })
  }

  loadCitiesOptions = async inputValue => {
    if (inputValue.length >= 3) {
      let url = `${config.SERVER_API_URL}/v1/cities?filter[q]=${encodeURIComponent(inputValue)}&include=province`
      let response = await axios({ url, method: 'get' })
      let users_data = jsonapiToData(response.data)
      let citiesOpts = users_data.data.map(r => ({
        value: r.id,
        label: r.name + ` (${r.province.acronym})`,
        province: r.province.name,
      }))

      if (this.state.cityOptionLast.label && !citiesOpts.some(opt => opt.value === this.state.cityOptionLast.value)) {
        citiesOpts.push(this.state.cityOptionLast)
      }

      this.setState({ citiesOpts: citiesOpts })
      return citiesOpts
    } else return []
  }

  handleCapChange = ({ target }) => {
    if (!capRegExp.test(target.value) || target.value.length > 5) {
      return
    } else {
      this.props.onChange({ cap: target.value })
    }
  }

  render() {
    let { labels, citiesOpts, cityOptionLast } = this.state
    let { cap, errors, city, cityData } = this.props
    let { showRequiredMarks, isReadOnly } = this.props

    if (cityData?.name && !cityOptionLast.value) {
      citiesOpts.push({
        value: cityData.id,
        label: cityData.name + ` (${cityData.province.acronym})`,
        province: cityData.province.name,
      })
    }

    return (
      <>
        <Row className="mb-3 pe-0">
          <Form.Group as={Col} className="col-10 col-md-10">
            <Form.Label>{labels.city}</Form.Label>
            {showRequiredMarks === true && <span> *</span>}
            <StyledSelect
              className={`${'city_id' in errors && 'form-control is-invalid'} ${
                isReadOnly === true ? styles.read_only : ''
              }`}
              placeholder={labels.city}
              value={citiesOpts.find(o => parseInt(o.value) === parseInt(city))}
              onChange={this.handleSelectChange()}
              options={citiesOpts}
              isDisabled={isReadOnly === true}
              loadOptions={this.loadCitiesOptions}
            />
            <div
              class={`${'city_id' in errors && 'invalid-feedback d-block'} ${
                isReadOnly === true ? styles.read_only : ''
              }`}>
              {errors.city_id}
            </div>
          </Form.Group>
          <Form.Group as={Col} className="col-2 col-md-2 pe-0">
            <Form.Label>{labels.cap}</Form.Label>
            {showRequiredMarks === true && <span> *</span>}
            <Form.Control
              className={`${'cap' in errors && 'form-control is-invalid'} ${
                isReadOnly === true ? styles.read_only : ''
              }`}
              name="cap"
              disabled={isReadOnly === true}
              placeholder={isReadOnly === false ? labels.cap : ''}
              value={cap}
              onChange={this.handleCapChange}
              style={{ minHeight: '2.25rem' }}
            />
          </Form.Group>
        </Row>
      </>
    )
  }
}

export default connect(mstp)(SelectMunicipality)
