import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import i18next from 'i18next'

import config from 'config'
import { success, error } from 'components/system_wide/notification'
import transformJsonAPI from 'lib/jsonapi_to_data'
import { isAdmin, isCondominiaAdmin } from 'lib/utils'

const destinations = [
  { id: 'equivalent', value: 'equivalent', label: `Uguale per tutti` },
  { id: 'nominative', value: 'nominative', label: 'Nominativa' },
]
const communication_types = [
  { id: 'assemblea', value: 'assemblea', label: `Assemblea` },
  { id: 'generale', value: 'generale', label: 'Comunicazione generale' },
  { id: 'altro', value: 'altro', label: 'Altro...' },
]
const { t } = i18next

export const initialState = {
  errors: {},
  loading: false,
  show_add_mailing: false,
  mailing: [],
  total: 0,
  current_page: 1,
  validators: {},
  destinations: [],
  communication_types: [],
  print_options: [],
  condominia: [],
  current_mailer: {},
  recipients: [],
  elements: [],
}

export const getMailer = createAsyncThunk('mailing/get_mailer', async (mailer_id, thunkApi) => {
  try {
    let user = thunkApi.getState().userInfo
    let users = thunkApi.getState().usersData
    let { role_id, id: user_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}?include=payer,attachments,attachments.print_options,attachment_options,user`
    // Admin o Tecnico
    if (!isCondominiaAdmin(role_id, users.roles)) {
      url = `${config.SERVER_API_URL}/v1/mail_senders/${mailer_id}?include=payer,attachments,attachments.print_options,attachment_options,user,parent`
    }
    let response = await axios({ url, method: 'get' })
    let result = transformJsonAPI(response.data)
    return { ...(result.data?.[0] ?? {}) }
  } catch (err) {
    console.log(err)
    error(t('store.mailing.get_mailing_validators_error', 'Impossibile recuperare i validatori'))
    return thunkApi.rejectWithValue()
  }
})

export const getRecipients = createAsyncThunk('mailing/recipients_list', async (id, thunkApi) => {
  try {
    let state = thunkApi.getState().mailing
    let mailer_id = id ?? state.current_mailer.id
    let url = `${config.SERVER_API_URL}/v1/mail_senders/${mailer_id}/mail_sender_recipients?include=address,attachments,address.city.province.region`
    let response = await axios({ url, method: 'get' })
    let result = transformJsonAPI(response.data)
    return result.data
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.mailing.get_mailing_error', 'Impossibile recuperare le informazioni'))
    return thunkApi.rejectWithValue()
  }
})

export const getMailing = createAsyncThunk('mailing/list', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter, filterTracking, filterPayer, filterPayerType, filterState, filtered_users } =
      params
    let { sort_column, sort_direction } = sortData
    let current_page = parseInt(offset / limit) + 1 || 1

    let user = thunkApi.getState().userInfo
    let users = thunkApi.getState().usersData
    let { role_id, id: user_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders?`
    // Admin o Tecnico
    if (isCondominiaAdmin(role_id, users.roles)) {
      url = url + `&include=attachments,payer`
    } else {
      url = `${config.SERVER_API_URL}/v1/mail_senders?`
      url = url + `&include=attachments,user,payer,user.tax_datum`
    }
    url = url + `&page=${current_page}&per=${limit}&sort=-id`
    if (sort_column !== '') url = url + `&sort=${sort_direction === 'DESC' ? '' : '-'}${sort_column}`
    if (filter) url = url + `&filter[q]=${encodeURIComponent(filter)}`
    if (filterTracking) url = url + `&filter[with_tracking_code]=${encodeURIComponent(filterTracking)}`
    if (filterState) url = url + `&filter[with_current_state]=${encodeURIComponent(filterState?.id)}`
    if (filterPayerType === 'User' && filterPayer)
      url = url + `&filter[with_payer_as_user]=${encodeURIComponent(filterPayer)}`
    if (filterPayerType === 'Condominium' && filterPayer)
      url = url + `&filter[with_payer_as_condominium]=${encodeURIComponent(filterPayer)}`
    if (filtered_users) url = url + `&filter[with_user]=${encodeURIComponent(filtered_users.id)}`

    let response = await axios({ url, method: 'get' })
    let result = transformJsonAPI(response.data)
    return { ...result, current_page }
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.mailing.get_mailing_error', 'Impossibile recuperare le informazioni'))
    return thunkApi.rejectWithValue()
  }
})

export const acceptMailSender = createAsyncThunk('mailing/acceptMailSender', async ({ id }, thunkApi) => {
  try {
    let user = thunkApi.getState().userInfo
    let url = `${config.SERVER_API_URL}/v1/mail_senders/${id}`
    await axios({
      url,
      method: 'PUT',
      data: { worker_id: user.id },
    })
    success(t('store.mailing.accet_mailing_success', 'Postalizzazione accettata con successo'))
  } catch (err) {
    console.log(err)
    error(t('store.mailing.accept_mailing_error', 'Impossibile accettare la postalizzazione'))
    return thunkApi.rejectWithValue()
  }
})

export const createMailer = createAsyncThunk('mailing/createMailer', async ({ user_id, data }, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders?include=attachment,attachments.print_options`
    if (data?.user_id && data?.parent_id) {
      url = `${config.SERVER_API_URL}/v1/mail_senders?include=attachment,attachments.print_options`
    }
    let response = await axios({ url, method: 'POST', data })
    success(t('store.mailing.create_mailer', 'Postalizzazione aggiunta con successo'))
    let result = transformJsonAPI(response.data)
    return result.data
  } catch (err) {
    console.log(err)
    error(t('store.common.create_mailer_error', 'Impossibile salvare le informazioni'))
    return thunkApi.rejectWithValue()
  }
})
export const updateMailer = createAsyncThunk('mailing/updateMailer', async ({ user_id, mailer_id, data }, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}?include=attachments,attachments.print_options`
    let response = await axios({ url, method: 'PATCH', data })
    // success(t('store.mailing.create_mailer', 'Postalizzazione aggiornata con successo'))
    let result = transformJsonAPI(response.data)
    return result.data
  } catch (err) {
    console.log(err)
    error(t('store.common.create_mailer_error', 'Impossibile salvare le informazioni'))
    return thunkApi.rejectWithValue()
  }
})
export const createRecipient = createAsyncThunk(
  'mailing/createRecipient',
  async ({ user_id, mailer_id, data }, thunkApi) => {
    try {
      let { id, typology, email, pec, fiscal_identifier, send_typology, name, ...address_attributes } = data

      if (!address_attributes.street) {
        if (email) send_typology = 'email'
        if (pec) send_typology = 'pec'
      }

      let rep = {
        ...(data.id ? { id: data.id } : {}),
        typology,
        email,
        pec,
        fiscal_identifier,
        send_typology,
        name,
        ...(address_attributes?.street ? { address_attributes } : {}),
      }
      let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/mail_sender_recipients`
      if (rep.id)
        url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/mail_sender_recipients/${rep.id}`
      let response = await axios({ url, method: rep.id ? 'PATCH' : 'POST', data: { ...rep } })
      thunkApi.dispatch(getRecipients(mailer_id))
      success(t('store.mailing.create_mailer', 'Destinatario aggiunto con successo'))
      let result = transformJsonAPI(response.data)
      return result.data
    } catch (err) {
      console.log(err)
      error(t('store.common.create_mailer_error', 'Impossibile salvare le informazioni'))
      return thunkApi.rejectWithValue()
    }
  }
)
export const updateRecipient = createAsyncThunk(
  'mailing/updateRecipient',
  async ({ user_id, mailer_id, recipient }, thunkApi) => {
    try {
      let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/mail_sender_recipients/${recipient.id}?include=address`
      let response = await axios({ url, method: 'PATCH', data: { ...recipient } })
      let result = transformJsonAPI(response.data)
      return result.data
    } catch (err) {
      console.log(err)
      error(t('store.common.create_mailer_error', 'Impossibile salvare le informazioni'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const updateSent = createAsyncThunk(
  'mailing/updateSent',
  async ({ user_id, mailer_id, recipient }, thunkApi) => {
    try {
      let url = `${config.SERVER_API_URL}/v1/mail_senders/${mailer_id}/mail_sender_recipients/${recipient}/complete`
      let response = await axios({ url, method: 'POST' })
      let result = transformJsonAPI(response.data)
      return result.data
    } catch (err) {
      console.log(err)
      error(t('store.common.create_mailer_error', 'Impossibile salvare le informazioni'))
      return thunkApi.rejectWithValue()
    }
  }
)
export const getMailerAttachments = createAsyncThunk('mailing/attachments', async (_, thunkApi) => {
  try {
    let state = thunkApi.getState().mailing
    let mailer_id = state.current_mailer.id
    let user = thunkApi.getState().userInfo
    let users = thunkApi.getState().usersData
    let { role_id, id: user_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/attachments?include=print_options`
    // Admin o Tecnico
    if (isAdmin(role_id, users.roles)) {
      url = `${config.SERVER_API_URL}/v1/mail_senders/${mailer_id}/attachments?include=print_options`
    }
    let response = await axios({
      url,
      method: 'get',
    })
    let result = transformJsonAPI(response.data)
    let attachments = result?.data ?? []
    for (const a of attachments) {
      let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/attachments/${a.id}/mail_sender_attachment_options`
      let response = await axios({ url, method: 'get' })
      let result = transformJsonAPI(response.data)
      a.attachment_options = result?.data ?? { recipient_ids: [], typology: 'equivalent' }
    }
    return attachments
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.mailing.get_mailing_error', 'Impossibile recuperare le informazioni'))
    return thunkApi.rejectWithValue()
  }
})

export const createAttachment = createAsyncThunk(
  'mailing/createAttachment',
  async ({ user_id, data, recipients }, thunkApi) => {
    try {
      let state = thunkApi.getState().mailing
      let mailer_id = state.current_mailer.id
      let response = await axios({
        url: `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/attachments?include=print_options`,
        method: 'POST',
        data,
      })

      let { id } = response?.data?.data ?? null
      if (id && recipients.length > 0) {
        let formData = new FormData()
        formData.append('_jsonapi[typology]', 'nominative')
        for (const r of recipients) {
          formData.append('_jsonapi[recipient_ids][]', r)
        }
        await axios({
          url: `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/attachments/${id}/mail_sender_attachment_options`,
          method: 'POST',
          data: formData,
        })
      }
      success(t('store.mailing.create_mailer', 'Allegato aggiunto con successo'))
      let result = transformJsonAPI(response.data)
      return result.data
    } catch (err) {
      console.log(err)
      error(t('store.common.create_mailer_error', 'Impossibile salvare le informazioni'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const createDelegatedAttachment = createAsyncThunk(
  'mailing/createDelegatedAttachment',
  async ({ user_id, data, send_typology = 'email' }, thunkApi) => {
    try {
      let state = thunkApi.getState().mailing
      let mailer_id = state.current_mailer.id

      let recipientData = {
        typology: 'person',
        email: 'no-reply@conhive.it',
        pec: 'no-reply@conhive.it',
        fiscal_identifier: 'TEMPLATE',
        send_typology,
        name: 'TEMPLATE',
        send_digital_copy: false,
        address_attributes: {
          street: 'COME DA TEMPLATE',
          city_id: '1',
          city_custom: '',
          cap: '63076',
          country_id: '1',
        },
      }
      let urlRep = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/mail_sender_recipients`
      let responseRep = await axios({ url: urlRep, method: 'POST', data: { ...recipientData } })

      let response = await axios({
        url: `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/attachments?include=print_options`,
        method: 'POST',
        data,
      })

      let { id } = response?.data?.data ?? null
      let formData = new FormData()
      formData.append('_jsonapi[typology]', 'nominative')
      formData.append('_jsonapi[recipient_ids][]', responseRep?.data?.data?.id ?? '0')
      await axios({
        url: `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/attachments/${id}/mail_sender_attachment_options`,
        method: 'POST',
        data: formData,
      })

      await axios({
        url: `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/delegate`,
        method: 'POST',
      })

      success(t('store.mailing.create_mailer', 'Allegato di delegazione aggiunto con successo'))
      let result = transformJsonAPI(response.data)
      return result.data
    } catch (err) {
      console.log(err)
      error(t('store.common.create_mailer_error', 'Impossibile salvare le informazioni'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const confirmMailer = createAsyncThunk('mailing/confirmMailer', async ({ id }, thunkApi) => {
  try {
    let user = thunkApi.getState().userInfo
    let users = thunkApi.getState().usersData
    let { role_id, id: user_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${id}/confirm`
    if (!isCondominiaAdmin(role_id, users.roles)) {
      url = `${config.SERVER_API_URL}/v1/mail_senders/${id}/confirm`
    }
    await axios({ url, method: 'POST' })
    success(t('store.mailing.create_mailer', 'Postalizzazione finalizzata con successo'))
    return true
  } catch (err) {
    let message = err?.response?.data?.errors?.title ?? ''
    if (message === '') {
      error('Impossibile salvare le informazioni')
    } else {
      error(message)
    }
    return thunkApi.rejectWithValue()
  }
})

export const deleteAttachment = createAsyncThunk('mailing/deleteAttachment', async (id, thunkApi) => {
  try {
    let state = thunkApi.getState().mailing
    let mailer_id = state.current_mailer.id
    let url = `${config.SERVER_API_URL}/v1/mail_senders/${mailer_id}/attachments/${id}`
    await axios({
      url,
      method: 'DELETE',
    })
    success(t('store.mailing.delete_mailing_attachment_success', 'Cancellato con successo'))
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.mailing.delete_mailing_error', 'Impossibile cancellare'))
    return thunkApi.rejectWithValue()
  }
})

export const deleteRecipients = createAsyncThunk(
  'mailing/deleteRecipients',
  async ({ user_id, mailer_id, recipients }, thunkApi) => {
    try {
      for (let i = 0; i < recipients.length; i++) {
        let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailer_id}/mail_sender_recipients/${recipients[i]}`
        await axios({ url, method: 'DELETE' })
      }
      thunkApi.dispatch(getRecipients(mailer_id))
    } catch (err) {
      if (err?.message === 'canceled') return thunkApi.rejectWithValue()
      thunkApi.dispatch(getRecipients(mailer_id))
      error(t('store.mailing.delete_mailing_error', 'Impossibile cancellare'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const deleteMailer = createAsyncThunk('mailing/deleteMailer', async (mailing_id, thunkApi) => {
  try {
    let user = thunkApi.getState().userInfo || { role_id: 4, id: 0 }
    let users = thunkApi.getState().usersData
    let { role_id, id: user_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/mail_senders/${mailing_id}`
    if (!isCondominiaAdmin(role_id, users.roles)) {
      url = `${config.SERVER_API_URL}/v1/mail_senders/${mailing_id}`
    }
    await axios({ url, method: 'DELETE' })
    success(t('store.mailing.delete_mailing_success', 'Cancellato con successo'))
  } catch (err) {
    console.log(err)
    error(t('store.mailing.delete_mailing_error', 'Impossibile cancellare'))
    return thunkApi.rejectWithValue()
  }
})

export const getDestinations = createAsyncThunk('mailing/destinations', async (_, thunkApi) => {
  try {
    //   let url = `${config.SERVER_API_URL}/v1/models/mailing`
    //   let response = await axios({
    //     url,
    //     method: 'get',
    //   })
    // return response.data.data
    return { data: destinations }
  } catch (err) {
    console.log(err)
    error(t('store.mailing.get_mailing_destinations_error', 'Impossibile recuperare le destinazioni'))
    return thunkApi.rejectWithValue()
  }
})
export const getCommunications = createAsyncThunk('mailing/communications', async (_, thunkApi) => {
  try {
    //   let url = `${config.SERVER_API_URL}/v1/models/mailing`
    //   let response = await axios({
    //     url,
    //     method: 'get',
    //   })
    // return response.data.data
    return { data: communication_types }
  } catch (err) {
    console.log(err)
    error(t('store.mailing.get_mailing_communications_error', 'Impossibile recuperare i tipi di comunicazione'))
    return thunkApi.rejectWithValue()
  }
})
export const getPrintOptions = createAsyncThunk('mailing/print_options', async (_, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/print_options`
    let response = await axios({
      url,
      method: 'get',
    })
    let result = transformJsonAPI(response.data)
    let options = result.data.reduce((acc, o) => ({ ...acc, [o.category]: [o, ...(acc?.[o.category] ?? [])] }), {})
    return { data: options }
  } catch (err) {
    console.log(err)
    error(t('store.mailing.get_mailing_print_options_error', 'Impossibile recuperare le opzioni di stampa'))
    return thunkApi.rejectWithValue()
  }
})

export const getUserCondominia = createAsyncThunk('mailing/condominia_list', async (user_id, thunkApi) => {
  try {
    let user = thunkApi.getState().userInfo
    let users = thunkApi.getState().usersData
    let { role_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/condominia?fields[condominia][]=name&per=-1`
    // Admin o Tecnico
    if (!isCondominiaAdmin(role_id, users.roles)) {
      url = `${config.SERVER_API_URL}/v1/condominia?fields[condominia][]=name&per=-1`
    }
    let response = await axios({ url, method: 'get' })
    let condominia_data = transformJsonAPI(response.data)
    let condominiaOpts = condominia_data.data.map(c => ({
      id: c.id,
      value: c.id,
      label: c.name,
      user_id,
    }))
    return condominiaOpts
  } catch (err) {
    console.log(err)
    error(t('store.condominia.get_condominia_error', 'Impossibile recuperare i condomini'))
    return thunkApi.rejectWithValue()
  }
})
export const getUserCondominium = createAsyncThunk('mailing/condominium_show', async ({ user_id, c_id }, thunkApi) => {
  try {
    let user = thunkApi.getState().userInfo
    let users = thunkApi.getState().usersData
    let { role_id } = user

    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/condominia/${c_id}?fields[condominia][]=name`
    // Admin o Tecnico
    if (!isCondominiaAdmin(role_id, users.roles)) {
      url = `${config.SERVER_API_URL}/v1/condominia/${c_id}?fields[condominia][]=name`
    }
    let response = await axios({ url, method: 'get' })
    let condominia_data = transformJsonAPI(response.data)
    let condominiaOpts = condominia_data.data.map(c => ({
      id: c.id,
      value: c.id,
      label: c.name,
      user_id,
    }))
    return condominiaOpts
  } catch (err) {
    console.log(err)
    error(t('store.condominia.get_condominia_error', 'Impossibile recuperare i condomini'))
    return thunkApi.rejectWithValue()
  }
})
export const getElements = createAsyncThunk('mailing/elements', async ({ user_id, condominium_id }, thunkApi) => {
  try {
    let user = thunkApi.getState().userInfo
    let users = thunkApi.getState().usersData
    let { role_id } = user
    let url = `${config.SERVER_API_URL}/v1/users/${user_id}/condominia/${condominium_id}/recipients?include=address`
    if (!isCondominiaAdmin(role_id, users.roles)) {
      let mailing = thunkApi.getState().mailing
      let { user: mailer_user } = mailing.current_mailer
      url = `${config.SERVER_API_URL}/v1/users/${mailer_user.id}/condominia/${condominium_id}/recipients?include=address`
    }
    let response = await axios({ url, method: 'get' })
    let result = transformJsonAPI(response.data)
    return result
  } catch (err) {
    console.log(err)
    error(t('store.mailing.get_recipients_error', 'Impossibile recuperare i destinatari'))
    return thunkApi.rejectWithValue()
  }
})

export const setCondominiaRecipients = createAsyncThunk(
  'mailing/elements',
  async ({ user_id, condominium_id, recipient }, thunkApi) => {
    try {
      let url = `${config.SERVER_API_URL}/v1/users/${user_id}/condominia/${condominium_id}/recipients?include=address`
      let response = await axios({
        url,
        method: 'POST',
        data: recipient,
      })
      let result = transformJsonAPI(response.data)
      return result
    } catch (err) {
      console.log(err)
      error(t('store.mailing.get_recipients_error', 'Impossibile recuperare i destinatari'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const getValidators = createAsyncThunk('mailing/get_validators', async (_, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/models/mail_sender_recipient`
    let response = await axios({ url, method: 'get' })
    return response.data.data
  } catch (err) {
    console.log(err)
    error(t('store.transfers.get_transfers_validators_error', 'Impossibile recuperare i validatori'))
    return thunkApi.rejectWithValue()
  }
})

export const mailingSlice = createSlice({
  name: 'mailing',
  initialState,

  reducers: {
    resetCondominia: state => {
      state.condominia = []
    },
    resetUsers: state => {
      state.elements = []
    },
    filterStates: (state, action) => {
      state.filtered_states = action.payload
    },
    updateErrors: (state, action) => {
      state.errors = action.payload
    },
    setCurrentMailer: (state, action) => {
      state.current_mailer = action.payload
    },
    resetCurrentMailer: (state, action) => {
      state.current_mailer = {}
    },
  },
  extraReducers: {
    [getDestinations.fulfilled]: (state, action) => {
      state.destinations = action.payload.data
    },
    [getDestinations.rejected]: state => {
      state.destinations = []
    },
    [getCommunications.fulfilled]: (state, action) => {
      state.communication_types = action.payload.data
    },
    [getCommunications.rejected]: state => {
      state.communication_types = []
    },
    [getPrintOptions.fulfilled]: (state, action) => {
      state.print_options = action.payload.data
    },
    [getPrintOptions.rejected]: state => {
      state.print_options = []
    },
    [getUserCondominia.fulfilled]: (state, action) => {
      state.condominia = action.payload
    },
    [getUserCondominia.rejected]: state => {
      state.condominia = []
    },
    [getUserCondominium.fulfilled]: (state, action) => {
      state.condominia = action.payload
    },
    [getUserCondominium.rejected]: state => {
      state.condominia = []
    },
    [getElements.fulfilled]: (state, action) => {
      state.elements = action.payload.data
    },
    [getElements.rejected]: state => {
      state.elements = []
    },
    [getMailing.fulfilled]: (state, action) => {
      let { data, current_page, total } = action.payload
      state.mailing = current_page === 1 ? data : [...state.mailing, ...data]
      state.total = total
      state.current_page = current_page
      state.loading = false
    },
    [getMailing.rejected]: state => {
      state.total = initialState.total
      state.mailing = initialState.mailing
      state.loading = false
      state.current_page = 1
    },
    [getRecipients.fulfilled]: (state, action) => {
      state.recipients = action.payload
      state.loading = false
    },
    [getRecipients.rejected]: state => {
      state.recipients = []
      state.loading = false
    },
    [getMailer.fulfilled]: (state, action) => {
      state.current_mailer = { ...action.payload }
    },
    [getMailer.rejected]: state => {
      state.current_mailer = {}
    },
    [createMailer.fulfilled]: (state, action) => {
      state.current_mailer = { ...action.payload[0] }
    },
    [createMailer.rejected]: state => {
      state.current_mailer = {}
    },
    [createAttachment.fulfilled]: (state, action) => {
      // state.current_mailer.attachments = [...state.current_mailer.attachments, action.payload[0]]
    },
    [createAttachment.rejected]: state => {
      // non fa niente
    },
    [createDelegatedAttachment.fulfilled]: (state, action) => {
      // state.current_mailer.attachments = [...state.current_mailer.attachments, action.payload[0]]
    },
    [createDelegatedAttachment.rejected]: state => {
      // non fa niente
    },
    [updateMailer.fulfilled]: (state, action) => {
      state.current_mailer = { ...action.payload[0] }
    },
    [updateMailer.rejected]: state => {
      state.current_mailer = { ...state.current_mailer }
    },
    [updateRecipient.fulfilled]: (state, action) => {
      state.recipients = state.recipients.map(r => {
        if (r.id === action.payload[0].id) {
          return action.payload[0]
        } else {
          return r
        }
      })
    },
    [updateRecipient.rejected]: state => {
      // state.current_mailer = { ...state.current_mailer }
    },
    [updateSent.fulfilled]: (state, action) => {
      state.recipients = state.recipients.map(r => {
        if (r.id === action.payload[0].id) {
          return action.payload[0]
        } else {
          return r
        }
      })
    },
    [updateSent.rejected]: state => {
      // state.current_mailer = { ...state.current_mailer }
    },
    [getMailerAttachments.fulfilled]: (state, action) => {
      state.current_mailer.attachments = action.payload
    },
    [getMailerAttachments.rejected]: state => {
      state.current_mailer.attachments = []
    },
    [getValidators.fulfilled]: (state, action) => {
      state.validators = action.payload?.validators ?? {}
    },
    [getValidators.rejected]: state => {
      state.validators = {}
    },
  },
})

export const { resetCondominia, resetUsers, filterStates, updateErrors, setCurrentMailer, resetCurrentMailer } =
  mailingSlice.actions
export default mailingSlice.reducer
