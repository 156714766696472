import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'

export default class PasswordInput extends React.PureComponent {
  static defaultProps = {
    value: '',
    name: 'password',
    placeholder: '*******',
    onChange: () => {},
    errors: {},
    disabled: false,
  }
  state = { showPass: false }
  togglePassword = () => this.setState({ showPass: !this.state.showPass })

  render() {
    let { showPass } = this.state
    let { value, name, placeholder, onChange, errors, disabled, ...othersProps } = this.props
    return (
      <InputGroup>
        <Form.Control
          autoComplete="new-password"
          name={name}
          placeholder={placeholder}
          type={showPass ? 'text' : 'password'}
          value={value}
          onChange={onChange}
          isInvalid={name in errors}
          disabled={disabled}
          {...othersProps}
        />
        <InputGroup.Text onClick={this.togglePassword}>
          {showPass ? <i className="fa fa-eye-slash" /> : <i className="fa fa-eye" />}
        </InputGroup.Text>
      </InputGroup>
    )
  }
}
