import i18next from 'i18next'
import { isNumeric } from 'validator'
import isIBAN from 'validator/lib/isIBAN'

const { t } = i18next

const validateFields = data => {
  let requiredFields = ['name', 'fiscal_code', 'city_id', 'cap', 'street']

  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    if (!data[field]) errors[field] = t('condominia.form.errors.required_field', 'Campo richiesto')
  }

  if (data.iban && !isIBAN(data.iban)) {
    errors.iban = errors.iban ? errors.iban : t('condominia.form.errors.invalid_email', 'Formato IBAN non valido')
  }

  if (data.fiscal_code && !isNumeric(data.fiscal_code)) {
    errors['fiscal_code'] = t('condominia.form.errors.required_field', 'Il codice fiscale deve essere un numero!')
  }
  if (data.fiscal_code && data.fiscal_code.length !== 11) {
    errors['fiscal_code'] = t(
      'condominia.form.errors.required_field',
      'Il codice fiscale deve avere una lunghezza di 11 caratteri!'
    )
  }

  return errors
}

export default validateFields
