import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import i18next from 'i18next'
import axios from 'lib/axios'

import jsonapiToData from 'lib/jsonapi_to_data'

import { error } from 'components/system_wide/notification'
import config from 'config'

const { t } = i18next

export const initialState = {
  fadebar: false,
  loading: 0,
  cities: [],
  regions: [],
  provinces: [],
  redirect: '',
  server_status: true,
  net_status: true,
  current_version: `${config.VERSION}`,
  server_version: `${config.VERSION}`,
  api_version: 'Ultima versione!',
  current_city: {},
}

export const serverCheck = createAsyncThunk('common/serverCheck', async (_, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/ping`,
      method: 'GET',
    })
    let { api, ui } = response.data
    let ui_version = ui ?? 'Ultima versione'
    let api_version = api ?? 'Ultima versione!'
    return { ui_version, api_version }
  } catch (err) {
    return thunkApi.rejectWithValue()
  }
})

export const netCheck = createAsyncThunk('common/netCheck', async (_, thunkApi) => {
  try {
    return navigator.onLine
  } catch (err) {
    return thunkApi.rejectWithValue()
  }
})

export const getCity = createAsyncThunk('common/get_city', async (city_id, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/cities/${city_id}?include=province,province.region`,
      method: 'GET',
    })
    let { id, attributes } = response.data.data
    let { included } = response.data
    let region = included.find(r => parseInt(r.id) === parseInt(attributes?.region_id)) ?? {}
    let province = included.find(p => parseInt(p.id) === parseInt(attributes?.province_id)) ?? {}

    let city = {
      id,
      ...attributes,
      region: region?.attributes?.name ?? '',
      province: province?.attributes?.name ?? '',
    }
    return city
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.common.get_city_error', 'Impossibile recuperare le info della città'))
    return thunkApi.rejectWithValue()
  }
})

export const getCitiesByName = createAsyncThunk('common/get_cities', async (city_name, thunkApi) => {
  try {
    let responseData = []
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/cities?filter[q]=${encodeURIComponent(
        city_name
      )}&include=province`,
      method: 'GET',
    })
    responseData = jsonapiToData(response.data)
    let cities = responseData.data.map(r => ({ value: r.id, label: r.name + ` (${r.province.acronym})`, province: r.province.name }))
    return cities

  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.common.get_city_error', 'Nessuna città trovata'))
    return thunkApi.rejectWithValue()
  }
})

export const getRegions = createAsyncThunk('common/get_regions', async (_, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/regions`,
      method: 'GET',
    })
    let regions = response.data.data.map(r => ({ value: r.id, label: r.attributes.name }))
    return regions
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.common.get_regions_error', 'Impossibile recupeare le regioni'))
    return thunkApi.rejectWithValue()
  }
})

export const getProvinces = createAsyncThunk('common/get_provinces', async (region_id, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/regions/${region_id}/provinces`,
      method: 'GET',
    })
    let provinces = response.data.data.map(r => ({ value: r.id, label: r.attributes.name }))
    return provinces
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.common.get_provinces_error', 'Impossibile recupeare le province'))
    return thunkApi.rejectWithValue()
  }
})

export const getCities = createAsyncThunk('common/cities', async ({ region, province }, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/regions/${region}/provinces/${province}/cities`,
      method: 'GET',
    })

    let cities = response.data.data.map(r => ({ value: r.id, label: r.attributes.name }))
    return cities
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.common.get_cities_error', 'Impossibile recupeare le città'))
    return thunkApi.rejectWithValue()
  }
})

export const commonSlice = createSlice({
  name: 'common',
  initialState,

  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    toggleFadebar: (state, action) => {
      state.fadebar = typeof action.payload !== 'undefined' ? action.payload : !state.fadebar
    },
    redirectLocation: (state, action) => {
      state.redirect = action.payload
    },
  },
  extraReducers: {
    [getCities.fulfilled]: (state, action) => {
      state.cities = action.payload
    },
    [getCitiesByName.fulfilled]: (state, action) => {
      state.cities = action.payload
    },
    [getCity.fulfilled]: (state, action) => {
      state.current_city = action.payload
    },
    [getCity.rejected]: state => {
      state.current_city = {}
    },
    [getRegions.fulfilled]: (state, action) => {
      state.regions = action.payload
    },
    [getRegions.rejected]: state => {
      state.regions = []
    },
    [getProvinces.fulfilled]: (state, action) => {
      state.provinces = action.payload
    },
    [getProvinces.rejected]: state => {
      state.provinces = []
    },
    [netCheck.fulfilled]: (state, action) => {
      state.net_status = action.payload
    },
    [netCheck.rejected]: state => {
      state.net_status = false
    },
    [serverCheck.fulfilled]: (state, action) => {
      state.server_version = action.payload.ui_version
      state.api_version = action.payload.api_version
      state.server_status = action.payload.ui_version
    },
    [serverCheck.rejected]: state => {
      state.server_version = initialState.server_version
      state.api_version = initialState.api_version
      state.server_status = false
    },
  },
})

export const { setLoading, toggleFadebar, redirectLocation } = commonSlice.actions
export default commonSlice.reducer
