import BulkImport from 'components/system_wide/bulk_import'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import withJWTSession from 'enhancers/jwt_session'
import i18next from 'i18next'
import { isAdmin, isCondominiaAdmin } from 'lib/utils'
import validateFields from 'lib/validators/mailing'
import React from 'react'
import { Button, Form, Spinner, Table } from 'react-bootstrap'
import { DebounceInput } from 'react-debounce-input'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  createDelegatedAttachment,
  createRecipient,
  deleteMailer,
  deleteRecipients,
  getElements,
  getMailer,
  getPrintOptions,
  getRecipients,
  getUserCondominia,
  getUserCondominium,
  updateMailer,
} from 'store/mailing'
import StyledSelect from '../system_wide/styled_select'
import AddAttachment from './add_attachment'
import Breadcrumbs from './components/breadcrumbs'
import NewRecipient from './new_recipient'

const { t } = i18next

const mstp = state => {
  let { condominia, elements, validators, errors, current_mailer, recipients, print_options } = state.mailing
  let { role_id, id: my_user_id } = state.userInfo
  let { strings: trans } = state.translations
  let { roles } = state.usersData
  return {
    condominia,
    elements,
    my_user_id,
    validators,
    errors,
    current_mailer,
    recipients,
    isAdmin: isAdmin(role_id, roles),
    isCondominiaAdmin: isCondominiaAdmin(role_id, roles),
    trans,
    print_options,
  }
}

class SelectTargets extends React.Component {
  static defaultProps = {
    administrators: [],
    condominia: [],
    elements: [],
    validators: {},
  }
  initialState = {
    filter_type: null,
    user_id: null,
    selected_condominium: null,
    selected_elements: [],
    selected_recipients: [],
    filter_element: '',
    filter_recipient: '',
    show_bulk_modal: false,
    show_new_recipient_modal: false,
    show_loading: false,
    save_recipients: false,
    errors_dest: false,
    selectedFile: '',
    selectedFileLabel: '',
    selected_options: {},
    send_typology: 'paper',
    show_to: '',
  }
  state = { ...this.initialState }

  async componentDidMount() {
    // await this.props.dispatch(getValidators())
    if (this.props.router.params.mailer_id) {
      await this.props.dispatch(getMailer(this.props.router.params.mailer_id))
      await this.props.dispatch(getRecipients(this.props.router.params.mailer_id))
      let { current_mailer } = this.props
      if (current_mailer.payer_type === 'Condominium') {
        await this.props.dispatch(
          getUserCondominium({ user_id: current_mailer.payer.user_id, c_id: current_mailer.payer.id })
        )
        await this.props.dispatch(
          getElements({ user_id: current_mailer.payer.user_id, condominium_id: current_mailer.payer.id })
        )
        this.setState({
          selected_condominium: current_mailer.payer.id,
          user_id: current_mailer.payer.user_id,
          save_recipients: current_mailer?.update_recipients ?? false,
        })
      } else {
        await this.props.dispatch(getUserCondominia(current_mailer.payer_id))
        this.setState({ user_id: current_mailer.payer_id, save_recipients: current_mailer?.update_recipients ?? false })
      }
    }
    if (this.props.print_options.length === 0) {
      await this.props.dispatch(getPrintOptions())
    }
    this.setState({
      selected_options: {
        ...Object.keys(this.props.print_options).reduce(
          (acc, o) => ({ ...acc, [o]: this.props.print_options[o].find(op => op.default === true)?.id }),
          {}
        ),
      },
    })
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.selected_condominium !== this.state.selected_condominium) {
      let { user_id, selected_condominium } = this.state
      await this.props.dispatch(getElements({ user_id, condominium_id: selected_condominium }))
    }
  }

  handleSelectChange = async option => {
    this.setState({ selected_condominium: option.id })
  }

  checkElement = id => () => {
    let { selected_elements } = this.state
    if (selected_elements.includes(id)) {
      selected_elements = selected_elements.filter(s => s !== id)
    } else {
      selected_elements.push(id)
    }
    this.setState({ selected_elements })
  }

  checkRecipients = id => () => {
    let { selected_recipients } = this.state
    if (selected_recipients.includes(id)) {
      selected_recipients = selected_recipients.filter(s => s !== id)
    } else {
      selected_recipients.push(id)
    }
    this.setState({ selected_recipients })
  }

  isFormValid = () => {
    let { user_id, selected_elements } = this.state
    let valid = validateFields({ user_id, selected_elements }, this.props.validators)
    return valid
  }

  onFilterChange = e => this.setState({ filter_element: e.target.value })
  onFilterRepChange = e => this.setState({ filter_recipient: e.target.value })

  checkAll = () => {
    let { elements } = this.props
    let { filter_element, selected_elements } = this.state
    // let elements = []
    if (filter_element !== '') {
      elements = elements.filter(
        e =>
          e.name.toLowerCase().includes(filter_element) ||
          e.fiscal_identifier.toLowerCase().includes(filter_element) ||
          e?.address?.street.toLowerCase().includes(filter_element)
      )
    }
    // if (filter_type === 'condominia') elements = condominia
    // if (filter_type === 'users') elements = users

    if (selected_elements.length < elements.length) {
      this.setState({ selected_elements: elements.map(e => e.id) })
    }
    if (selected_elements.length === elements.length) {
      this.setState({ selected_elements: [] })
    }
  }
  checkAllRecipients = () => {
    let { recipients } = this.props
    let { filter_recipient, selected_recipients } = this.state
    let reps = [...recipients]
    if (filter_recipient !== '') {
      reps = reps.filter(
        e =>
          e.name.toLowerCase().includes(filter_recipient) ||
          e.fiscal_identifier.toLowerCase().includes(filter_recipient) ||
          e?.address?.street.toLowerCase().includes(filter_recipient)
      )
    }

    if (selected_recipients.length < reps.length) {
      this.setState({ selected_recipients: reps.map(e => e.id) })
    }
    if (selected_recipients.length === reps.length) {
      this.setState({ selected_recipients: [] })
    }
  }

  toggleBulkModal = async () => {
    await this.props.dispatch(getRecipients())
    this.setState({ show_bulk_modal: !this.state.show_bulk_modal })
  }
  toggleNewModal = async () => {
    this.setState({ show_new_recipient_modal: !this.state.show_new_recipient_modal })
  }
  toggleDelegateModal = async () => {
    this.setState({ show_delegate_modal: !this.state.show_delegate_modal })
  }

  closeModal = async () => {
    this.setState({ show_bulk_modal: false, show_new_recipient_modal: false, show_loading: true })
    setTimeout(async () => {
      await this.props.dispatch(getRecipients())
      this.setState({ show_loading: false })
    }, 3000)
  }
  handleAddRecipients = async () => {
    let { elements } = this.props
    let { selected_elements } = this.state
    for (const s of selected_elements) {
      let el = elements.find(e => e.id === s)
      if (el) {
        let { id, ...rest } = el
        let { id: ida, ...address } = rest?.address ?? []
        el = { ...rest, ...(address ?? []) }
      }
      await this.addNewRecipient(el)
    }
    this.setState({ selected_elements: [] })
  }
  addNewRecipient = async data => {
    let { current_mailer, my_user_id } = this.props
    await this.props.dispatch(createRecipient({ user_id: my_user_id, mailer_id: current_mailer.id, data }))
    await this.props.dispatch(getRecipients())
    this.setState({ errors_dest: false })
    this.closeModal()
  }
  handleDelete = async () => {
    let { current_mailer } = this.props
    showConfirmDialog(
      'Elimina postalizzazione',
      'Sei sicuro di voler annullare la postalizzazione in corso? Tutti i dati inseriti andranno persi!',
      async () => {
        if (current_mailer.id) await this.props.dispatch(deleteMailer(current_mailer.id))
        this.props.navigate('/mailing')
      },
      () => {},
      'danger',
      'Annulla postalizzazione'
    )
  }

  removeRecipients = async () => {
    let { current_mailer, my_user_id } = this.props
    let { selected_recipients } = this.state
    await this.props.dispatch(
      deleteRecipients({ user_id: my_user_id, mailer_id: current_mailer.id, recipients: selected_recipients })
    )
    this.setState({ selected_recipients: [] })
  }

  saveRecipientsOnCondominium = async () => {
    this.setState({ save_recipients: !this.state.save_recipients })
  }

  // saveRecipients = async () => {
  //   let { current_mailer, recipients } = this.props
  //   for (const r of recipients) {
  //     let { id, ...address_attributes } = r.address
  //     let recipient = {
  //       name: r.name,
  //       email: r.email,
  //       pec: r.pec,
  //       fiscal_identifier: r.fiscal_identifier,
  //       address_attributes,
  //     }
  //     await this.props.dispatch(
  //       setCondominiaRecipients({ user_id: current_mailer.user_id, condominium_id: current_mailer.payer_id, recipient })
  //     )
  //   }
  // }

  goForward = async () => {
    let { current_mailer, recipients } = this.props
    let { selected_recipients, save_recipients } = this.state
    if (selected_recipients.length > 0 || recipients.length > 0) {
      if (save_recipients) {
        // await this.saveRecipients()
        await this.props.dispatch(
          updateMailer({
            user_id: current_mailer.user.id,
            mailer_id: current_mailer.id,
            data: { update_recipients: true },
          })
        )
      }
      this.props.router.navigate(`/mailing/summary/${current_mailer.id}`)
    } else {
      this.setState({ errors_dest: true })
    }
  }

  FileUploadHandler = e => {
    this.setState({ selectedFile: e.target.files[0] })
  }

  setUploadForm = form => {
    this.uploadForm = form
  }
  checkOptions = (o, id) => () => {
    this.setState({ selected_options: { ...this.state.selected_options, [o]: [id] } })
  }
  changeSendTypology = value => {
    this.setState({ send_typology: value })
  }
  uploadFile = async () => {
    let { selectedFile, selected_options, send_typology } = this.state
    let { my_user_id, current_mailer } = this.props

    let formData = new FormData()
    formData.append('_jsonapi[document]', selectedFile)
    Object.keys(selected_options).forEach(o => {
      let pr_opt = selected_options[o]
      formData.append('_jsonapi[associable_print_options_attributes][][print_option_id]', pr_opt)
    })
    await this.props.dispatch(createDelegatedAttachment({ user_id: my_user_id, data: formData, send_typology }))
    this.uploadForm.value = ''
    this.props.router.navigate(`/mailing/summary/${current_mailer.id}`)
    this.setState({
      selected_options: {
        ...Object.keys(this.props.print_options).reduce(
          (acc, o) => ({ ...acc, [o]: this.props.print_options[o].find(op => op.default === true)?.id }),
          {}
        ),
      },
    })
  }

  renderSentTo = e => {
    let { show_to } = this.state
    let address = `${e?.address?.street ?? e?.street} - ${e?.address?.cap} ${e?.address?.city?.name}`
    if (address.includes('undefined') && e?.address?.city_id === undefined) address = ''
    let email = e?.email ?? ''
    let pec = e?.pec ?? ''
    if (show_to) {
      switch (show_to.id) {
        case 'email':
          return email
        case 'pec':
          return pec
        case 'address':
          return address
        default:
          return ''
      }
    } else
      return (
        <div className="d-flex gap-2 p-1">
          <i className={`fa fa-envelope ${email ? 'text-primary' : 'text-secondary'}`} title={email} />
          <i className={`fa fa-envelope-circle-check ${pec ? 'text-primary' : 'text-secondary'}`} title={pec} />
          <i className={`fa fa-map-location ${address ? 'text-primary' : 'text-secondary'}`} title={address} />
        </div>
      )
  }
  handleFilterTo = option => {
    this.setState({ show_to: option ?? '' })
  }

  render() {
    let {
      condominia,
      isAdmin,
      errors,
      my_user_id,
      current_mailer,
      recipients,
      elements,
      validators,
      print_options,
      trans,
    } = this.props
    let {
      selected_condominium,
      selected_elements,
      filter_element,
      filter_recipient,
      selected_recipients,
      show_loading,
      save_recipients,
      errors_dest,
      selectedFile,
      selectedFileLabel,
      selected_options,
      send_typology,
      show_to,
    } = this.state

    let user_id = my_user_id

    let allDisabled = current_mailer.current_state !== 'open' || typeof current_mailer.current_state === 'undefined'
    let { print_options: poTrans } = trans?.tables || {}

    let elements_list =
      filter_element !== ''
        ? elements.filter(
            e =>
              e.name.toLowerCase().includes(filter_element) ||
              e.fiscal_identifier.toLowerCase().includes(filter_element) ||
              e?.address?.street.toLowerCase().includes(filter_element)
          )
        : elements

    let recipients_list =
      filter_recipient !== ''
        ? recipients.filter(
            e =>
              e.name.toLowerCase().includes(filter_recipient) ||
              e.fiscal_identifier.toLowerCase().includes(filter_recipient) ||
              e?.address?.street.toLowerCase().includes(filter_recipient)
          )
        : recipients

    return (
      <div className="d-flex flex-column w-100 overflow-auto m-2 ms-3 me-3 z-1">
        <div className="d-flex flex-wrap gap-2 mb-2 w-100 justify-content-between">
          <div className="p-1">
            <h4 className="text-primary">
              <Trans i18nKey="mailing.modal_new_title">Nuova postalizzazione - Seleziona destinatari</Trans>
            </h4>
            <Breadcrumbs page={1} />
          </div>
        </div>
        <div className="d-flex mb-1 mt-1 gap-2 flex-column bg-white h-100 p-3 border">
          <div className="row">
            <div className="col-5">
              <div className="text-muted modal-title">Seleziona destinatari per:</div>
              <StyledSelect
                valid={true}
                placeholder={''}
                value={condominia.find(el => el.id === selected_condominium)}
                onChange={this.handleSelectChange}
                options={condominia}
                cacheOptions={false}
                isDisabled={allDisabled}
              />
              <Form.Control.Feedback type="invalid">{errors.new_user_id}</Form.Control.Feedback>
            </div>
            <div className="col-7 d-flex flex-end align-items-end justify-content-end">
              <Button
                onClick={!this.props.disabled && this.toggleNewModal}
                disabled={this.props.disabled || allDisabled}
                style={{ maxHeight: '2.5rem', marginRight: 10 }}>
                <i className="fa fa-plus me-2"></i>
                Inserimento manuale
              </Button>
              <Button
                onClick={!this.props.disabled && this.toggleBulkModal}
                disabled={this.props.disabled || allDisabled}
                style={{ maxHeight: '2.5rem', marginRight: 10 }}>
                <i className="fa fa-upload me-2"></i>
                Carica tramite file xls
              </Button>
              {this.props.isCondominiaAdmin && (
                <AddAttachment
                  label="Delega a Conhive"
                  labelIcon="fa fa-file-import me-2"
                  errors={{}}
                  selectedFile={selectedFile}
                  selectedFileLabel={selectedFileLabel}
                  FileUploadHandler={this.FileUploadHandler}
                  setUploadForm={this.setUploadForm}
                  print_options={print_options}
                  poTrans={poTrans}
                  selected_options={selected_options}
                  checkOptions={this.checkOptions}
                  changeSendTypology={this.changeSendTypology}
                  send_typology={send_typology}
                  uploadFile={this.uploadFile}
                  selectRecipients={false}
                  delegate={true}
                  recipients={recipients}
                  disabled={allDisabled}
                />
              )}
            </div>
          </div>
          <div className="row border-top mt-3 pt-2 pb-2"></div>
          <div className="d-flex align-items-center mb-1" style={{ width: '18.5rem', marginLeft: 'auto' }}>
            <div className="me-3">Mostra:</div>
            <StyledSelect
              placeholder={''}
              value={show_to}
              onChange={this.handleFilterTo}
              options={[
                { id: 'email', label: 'Email' },
                { id: 'pec', label: 'Pec' },
                { id: 'address', label: 'Indirizzo' },
              ]}
              isClearable={true}
              isDisabled={allDisabled}
              menuPosition="fixed"
            />
          </div>
          <div className="row">
            <div className="overflow-auto col" style={{ height: '100%' }}>
              <div className="d-flex justify-content-between align-items-center">
                <h6>Destinatari disponibili</h6>
                <DebounceInput
                  debounceTimeout={300}
                  className="form-control mb-2 w-50"
                  onChange={this.onFilterChange}
                  value={filter_element}
                  placeholder={t('common.filter', 'Cerca..')}
                  disabled={elements.length === 0 || allDisabled}
                />
                {'selected_elements' in errors && (
                  <div className="pt-1 ps-3 text-danger">{errors.selected_elements}</div>
                )}
              </div>
              <div style={{ maxHeight: 450, overflow: 'auto' }}>
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ borderCollapse: 'separate', borderSpacing: 0, borderBottom: '1px solid #dee2e6' }}>
                  <thead style={{ position: 'sticky', top: 0, background: 'white', width: '101%' }}>
                    <tr>
                      <th className="border" style={{ width: '2rem', textAlign: 'center' }}>
                        <Form.Check
                          type="checkbox"
                          onChange={this.checkAll}
                          disabled={elements.length === 0}
                          checked={selected_elements.length > 0 && selected_elements.length === elements.length}
                        />
                      </th>
                      <th className="border">Soggetto</th>
                      <th className="border">Recapiti</th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: 470, overflow: 'auto' }}>
                    {elements_list.map(e => {
                      return (
                        <tr className="border" style={{ height: '1rem' }} key={`e-${e.id}`}>
                          <td style={{ textAlign: 'center' }}>
                            <Form.Check
                              type="checkbox"
                              onChange={this.checkElement(e.id)}
                              checked={selected_elements.find(s => e.id === s)}
                              disabled={allDisabled}
                            />
                          </td>
                          <td>{e.name}</td>
                          <td>{this.renderSentTo(e)}</td>
                        </tr>
                      )
                    })}
                    {elements_list.length === 0 && (
                      <tr>
                        <td colSpan="7" className="text-center pt-4 pb-4 w-100">
                          Nessun elemento trovato
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-1 d-flex flex-column gap-4 align-items-center justify-content-center mt-5">
              <Button
                className=""
                style={{ maxWidth: '3rem' }}
                disabled={allDisabled || selected_elements.length === 0}
                onClick={this.handleAddRecipients}>
                <i className="fa fa-arrow-right" />
              </Button>
              <Button
                className=""
                style={{ width: '2.5rem' }}
                disabled={allDisabled || selected_recipients.length === 0}
                onClick={this.removeRecipients}>
                <i className="fa fa-times" />
              </Button>
            </div>
            <div className="overflow-auto col" style={{ height: '100%' }}>
              <div className="d-flex justify-content-between align-items-center">
                <h6>Destinatari selezionati per l'invio</h6>
                <DebounceInput
                  debounceTimeout={300}
                  className="form-control mb-2 w-50"
                  onChange={this.onFilterRepChange}
                  value={filter_recipient}
                  placeholder={t('common.filter', 'Cerca..')}
                />
                {'selected_recipients' in errors && (
                  <div className="pt-1 ps-3 text-danger">{errors.selected_recipients}</div>
                )}
              </div>
              <div style={{ maxHeight: 450, overflow: 'auto' }}>
                {show_loading === true && (
                  <div
                    style={{
                      padding: 20,
                      marginTop: 50,
                      position: 'absolute',
                      zIndex: 1000,
                      width: '42%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                    }}>
                    <Spinner as="span" animation="border" size="md" role="status" aria-hidden="true" />
                  </div>
                )}
                <Table
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{ borderCollapse: 'separate', borderSpacing: 0, borderBottom: '1px solid #dee2e6' }}>
                  <thead
                    style={{
                      position: 'sticky',
                      top: 0,
                      background: 'white',
                      width: '101%',
                    }}>
                    <tr>
                      <th className="border" style={{ width: '2rem', textAlign: 'center' }}>
                        <Form.Check
                          type="checkbox"
                          onChange={this.checkAllRecipients}
                          disabled={recipients_list.length === 0 || allDisabled}
                          checked={
                            selected_recipients.length > 0 && selected_recipients.length === recipients_list.length
                          }
                        />
                      </th>
                      <th className="border">Soggetto</th>
                      <th className="border">Recapiti</th>
                    </tr>
                  </thead>
                  <tbody style={{ maxHeight: 470, overflow: 'auto' }}>
                    {recipients_list.map(e => {
                      return (
                        <tr key={`r-${e.id}`} style={{ opacity: show_loading === true ? 0.3 : 1 }}>
                          <td style={{ textAlign: 'center' }}>
                            <Form.Check
                              type="checkbox"
                              onChange={this.checkRecipients(e.id)}
                              checked={selected_recipients.find(s => e.id === s)}
                              disabled={allDisabled}
                            />
                          </td>
                          <td>{e.name}</td>
                          <td>{this.renderSentTo(e)}</td>
                        </tr>
                      )
                    })}
                    {recipients_list.length === 0 && (
                      <tr
                        style={{
                          background: errors_dest
                            ? 'repeating-linear-gradient(-45deg, white, white 10px, #ff0d0020 10px, #ff0d0020 20px)'
                            : '',
                        }}>
                        <td colSpan="7" className="text-center pt-4 pb-4 w-100">
                          Nessun elemento trovato
                          {errors_dest ? <div className="text-danger fw-bold">Campo obbligatorio</div> : ''}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center flex-end justify-content-end me-1">
            <Form.Check
              type="checkbox"
              onChange={this.saveRecipientsOnCondominium}
              checked={save_recipients}
              disabled={current_mailer.payer_type === 'User' || allDisabled}
            />
            <div className={`ms-2 ${current_mailer.payer_type === 'User' || allDisabled ? 'text-muted' : ''}`}>
              Salva destinatari come partecipanti del condominio
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 align-items-end mt-2" style={{ flex: 1 }}>
          <Button
            variant={current_mailer?.current_state === 'open' ? 'warning' : 'secondary'}
            className="float-md-end"
            onClick={this.handleDelete}
            disabled={current_mailer?.current_state !== 'open' && typeof current_mailer.current_state !== 'undefined'}>
            Cancella
          </Button>
          <div className="d-flex align-items-center">
            <Link to={`/mailing/edit/${current_mailer.id}`} className="me-3">
              <Button variant="secondary" className="float-md-end">
                <i className={`fa fa-arrow-left`} /> Indietro
              </Button>
            </Link>
            <Button variant="primary" className="float-md-end" onClick={this.goForward}>
              Procedi <i className={`fa fa-arrow-right`} />
            </Button>
          </div>
        </div>
        {this.state.show_bulk_modal === true && (
          <BulkImport
            isAdmin={isAdmin}
            current_user={user_id}
            model={'MailSenderRecipient'}
            className="w-100"
            title={t('mailing.import_bulk_title', 'Importa destinatari')}
            importUrl={`/v1/users/${user_id}/importers`}
            target={current_mailer.id}
            onDismiss={this.closeModal}
            trans={this.props.trans}
            skip_user={true}
          />
        )}
        {this.state.show_new_recipient_modal === true && (
          <NewRecipient closeModal={this.closeModal} onSave={this.addNewRecipient} validators={validators} />
        )}
      </div>
    )
  }
}

export default connect(mstp)(withJWTSession(SelectTargets))
