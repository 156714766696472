import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosLib from 'axios'
import i18next from 'i18next'
import axios from 'lib/axios'

import { error, success } from 'components/system_wide/notification'
import config from 'config'
import jsonapiToData from 'lib/jsonapi_to_data'

const { t } = i18next

export const initialState = {
  users: [],
  roles: {},
  rolesEntity: {},
  total: 0,
  show_form: false,
  current_user: {},
  errors: {},
  is_deactivated: false,
  created_user_id: null,
  filtered_roles: [],
  current_page: 1,
}

export const getRoles = createAsyncThunk('users/roles', async (_, thunkApi) => {
  try {
    let state = thunkApi.getState().usersData
    if (Object.keys(state.roles).length === 0) {
      let response = await axios({
        url: `${config.SERVER_API_URL}/v1/roles`,
        method: 'GET',
      })

      let { data: roles } = response.data
      let { data: rolesEntity } = jsonapiToData(response.data) ?? {}

      roles = roles.reduce((acc, r) => {
        let { id, attributes } = r
        let { codename } = attributes
        return { ...acc, [id]: codename }
      }, {})
      return {
        roles: roles,
        rolesEntity: rolesEntity,
      }
    } else {
      return {
        roles: state.roles,
        rolesEntity: state.rolesEntity,
      }
    }
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.users.get_roles_error', 'Impossibile recupeare le roles'))
    return thunkApi.rejectWithValue()
  }
})

export const getUsers = createAsyncThunk('users/list', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let state = thunkApi.getState().usersData
    let current_page = parseInt(offset / limit) + 1 || 1

    let url = `${config.SERVER_API_URL}/v1/users?include=role,tax_datum&page=${current_page}&per=${limit}`
    if (filter) url = url + `&filter[q]=${encodeURIComponent(filter)}`
    if (!state.is_deactivated) url = url + `&filter[is_deactivated]=false`
    if (state.filtered_roles.length > 0) url = url + `&filter[with_role]=${state.filtered_roles.join(',')}`
    if (sort_column !== '' && sort_column !== 'business_name')
      url = url + `&sort=${sort_direction === 'ASC' ? '' : '-'}${sort_column}`
    if (sort_column !== '' && sort_column === 'business_name')
      url = url + `&sort=${sort_direction === 'ASC' ? '' : '-'}scope:ordered_by_business_name`

    let response = await axios({
      url,
      method: 'get',
    })
    let users_data = jsonapiToData(response.data)
    users_data.data = users_data.data.map(el => ({
      ...el,
      tax_datum_required: el?.role?.tax_datum_required ?? true,
      business_name: el?.tax_datum?.business_name ?? '',
    }))
    return { users: users_data.data, total: users_data.total, reset: offset === 0, current_page }
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.users.get_users_error', 'Impossibile recuperare gli utenti'))
    return thunkApi.rejectWithValue()
  }
})

export const getSingleUser = async user_id => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/users/${user_id}?include=tax_datum.city.province.region,role,parent.tax_datum`,
      method: 'GET',
    })
    let userData = jsonapiToData(response.data)?.data?.[0] ?? {}
    return {
      id: userData.id,
      ...userData,
      role_id: userData?.role?.id,
      parent_id: userData?.parent?.id ?? '',
      tax_datum_required: userData?.role?.tax_datum_required ?? true,
      tax_datum_attributes: {
        ...userData?.tax_datum,
        region: userData?.tax_datum?.city?.province?.region?.id ?? null,
        province: userData?.tax_datum?.city?.province?.id ?? null,
        city_id: userData?.tax_datum?.city?.id,
        city: userData?.tax_datum?.city,
      },
    }
  } catch (err) {
    error(t('store.users.set_user_error', "Impossibile recuperare l'utente"))
    return {}
  }
}

export const setCurrentUser = createAsyncThunk('users/set_user', async (id, thunkApi) => {
  try {
    let user = getSingleUser(id)
    return user
  } catch (err) {
    console.log(err)
    error(t('store.users.set_user_error', "Impossibile recuperare l'utente"))
    return thunkApi.rejectWithValue()
  }
})

const _updateUser = async user => {
  try {
    let { id, ...rest } = user
    await axios({
      url: `${config.SERVER_API_URL}/v1/users/${id}`,
      method: 'patch',
      data: { ...rest },
    })
  } catch (err) {
    throw err
  }
}

export const updateUserProfile = createAsyncThunk('users/updateUserProfile', async (user, thunkApi) => {
  try {
    await _updateUser(user)
    thunkApi.dispatch(updateErrors({ errors: {} }))
    success(t('store.users.update_user_success', 'Utente aggiornato con successo'))
    return {}
  } catch (err) {
    let {
      data: { errors: from_server },
    } = err.response
    let errors = { from_server }
    thunkApi.dispatch(updateErrors({ errors }))
    error(t('store.users.update_user_error', 'Impossibile salvare le modifiche'))
    return thunkApi.rejectWithValue()
  }
})

export const updateUser = createAsyncThunk('users/updateUser', async (user, thunkApi) => {
  try {
    await _updateUser(user)
    thunkApi.dispatch(resetCurrentUser())
    thunkApi.dispatch(toggleShowForm())
    success(t('store.users.update_user_success', 'Utente aggiornato con successo'))
    return {}
  } catch (err) {
    console.log(err)
    let {
      data: { errors: from_server },
    } = err.response
    let errors = { from_server }
    thunkApi.dispatch(updateErrors({ errors }))
    error(t('store.users.update_user_error', 'Impossibile salvare le modifiche'))
    return thunkApi.rejectWithValue()
  }
})

export const createUser = createAsyncThunk('users/createUser', async (params, thunkApi) => {
  try {
    let { id, ...user } = params

    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/users`,
      method: 'POST',
      data: { ...user },
    })
    thunkApi.dispatch(resetCurrentUser())
    thunkApi.dispatch(toggleShowForm())
    success(t('store.users.create_user_success', 'Utente aggiunto con successo'))
    return response.data.data.id
  } catch (err) {
    let {
      data: { errors: from_server },
    } = err.response
    let errors = { from_server }
    thunkApi.dispatch(updateErrors({ errors }))
    error(t('store.users.create_user_error', "Impossibile creare l'utente!"))
    return thunkApi.rejectWithValue()
  }
})

export const deleteUser = createAsyncThunk('users/deleteUser', async (id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/v1/users/${id}`,
      method: 'DELETE',
    })
    success(t('store.users.delete_user_success', 'Utente disattivato con successo'))
    return {}
  } catch (err) {
    error(t('store.users.delete_user_error', "Impossibile disattivare l'utente!"))
    return thunkApi.rejectWithValue()
  }
})

export const activeUser = createAsyncThunk('users/activeUser', async (id, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/v1/users/${id}/reactivate`,
      method: 'PUT',
    })
    success(t('store.usersa.activate_user_success', 'Utente attivato con successo'))
    return {}
  } catch (err) {
    error(t('store.users.activate_user_error', "Impossibile attivare l'utente!"))
    return thunkApi.rejectWithValue()
  }
})

export const resetPasswordUser = createAsyncThunk('users/resetPasswordUser', async (email, thunkApi) => {
  try {
    await axiosLib({
      url: `${config.SERVER_API_URL}/users/password`,
      method: 'POST',
      data: { user: { email } },
    })
    success(t('store.usersa.email_reset_password_sended_success', 'Email per reset password inviata!'))
    return {}
  } catch (err) {
    error(t('store.users.email_reset_password_sended_error', "Impossibile inviare l'email di reset password!"))
    return thunkApi.rejectWithValue()
  }
})

export const usersSlice = createSlice({
  name: 'usersData',
  initialState,

  reducers: {
    toggleShowForm: state => {
      state.show_form = !state.show_form
    },
    toggleDeactivatedFilter: state => {
      state.is_deactivated = !state.is_deactivated
    },
    resetCurrentUser: state => {
      state.current_user = initialState.current_user
      state.errors = {}
    },
    updateErrors: (state, action) => {
      let { errors } = action.payload
      state.errors = errors
    },
    filterRoles: (state, action) => {
      state.filtered_roles = action.payload
    },
  },
  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, users, current_page } = action.payload
      state.total = total
      state.users = current_page === 1 ? [...users] : [...state.users, ...users]
    },
    [getUsers.rejected]: state => {
      state.total = initialState.total
      state.users = initialState.users
      state.current_page = initialState.current_page
    },
    [getRoles.fulfilled]: (state, action) => {
      if (!action?.payload) return
      state.roles = action.payload.roles
      state.rolesEntity = action.payload.rolesEntity
    },
    [getRoles.rejected]: state => {
      state.roles = initialState.roles
      state.rolesEntity = initialState.rolesEntity
    },
    [updateUser.fulfilled]: (state, action) => {},
    [updateUser.rejected]: state => {},
    [createUser.fulfilled]: (state, action) => {
      state.created_user_id = action.payload
    },
    [createUser.rejected]: state => {},
    [setCurrentUser.fulfilled]: (state, action) => {
      state.errors = {}
      state.current_user = action.payload
    },
    [setCurrentUser.rejected]: state => {
      state.current_user = {}
    },
  },
})

export const { toggleShowForm, resetCurrentUser, updateErrors, toggleDeactivatedFilter, filterRoles } =
  usersSlice.actions
export default usersSlice.reducer
