import i18next from 'i18next'
import isEmail from 'validator/lib/isEmail'

const { t } = i18next

const validateFields = (data, validators) => {
  // let requiredFields = Object.keys(validators)
  //   .filter(el => validators[el].type === 'PresenceValidator')
  //   .filter(s => s !== 'mail_sender')
  //   .filter(s => s !== 'email' || s !== 'pec' || s !== 'address')
  let requiredFields = ['name']

  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    if (!data[field]) errors[field] = t('user.user_form.errors.required_field', 'Campo richiesto')
  }

  if (data.email && !isEmail(data.email)) {
    errors.email = errors.email ? errors.email : t('user.user_form.errors.invalid_email', 'Formato email non valido')
  }
  if (data.pec && !isEmail(data.pec)) {
    errors.pec = errors.pec ? errors.pec : t('user.user_form.errors.invalid_email', 'Formato email non valido')
  }
  if (!data.email && !data.pec && !data.street) {
    errors.required_one = t(
      'user.user_form.errors.required_one',
      "Attenzione: E' neccessario specificare almeno uno dei valori: email, pec o indirizzo completo"
    )
  }
  let addressAll = [data.street, data.city_id, data.cap].every(
    v => v !== '' || v !== null || typeof v !== 'undefined'
  )
  let addressAllEmpty = [data.street, data.city_id, data.cap].every(v => !v)
  if (!addressAll || !addressAllEmpty) {
    if (!data.street) errors['street'] = t('user.user_form.errors.required_field', 'Campo richiesto')
    if (!data.city_id) errors['city_id'] = t('user.user_form.errors.required_field', 'Campo richiesto')
    if (!data.cap) errors['cap'] = t('user.user_form.errors.required_field', 'Campo richiesto')
  }
  return errors
}

export default validateFields
