import i18next from 'i18next'
import isEmail from 'validator/lib/isEmail'

const { t } = i18next

const validateFields = (data, isAdmin = false) => {
  let requiredFields = [
    'name',
    'surname',
    'email',
    'tax_datum_attributes.vat_number',
    'tax_datum_attributes.business_name',
  ]

  if (isAdmin) {
    requiredFields = requiredFields.filter(
      r => r !== 'tax_datum_attributes.vat_number' && r !== 'tax_datum_attributes.business_name'
    )
  }
  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    const nestedField = field.includes('tax_datum_attributes.') ? field.split('.')[1] : field
    if (!field.includes('tax_datum_attributes.')) {
      if (!data[field]) errors[field] = t('user.user_form.errors.required_field', 'Campo richiesto')
    } else {
      if (!data.tax_datum_attributes?.[nestedField]) {
        if (!errors.tax_datum_attributes) errors.tax_datum_attributes = {}
        errors[field] = t('user.user_form.errors.required_field', 'Campo richiesto')
      }
    }
  }

  if (!isEmail(data.email)) {
    errors.email = errors.email ? errors.email : t('user.user_form.errors.invalid_email', 'Formato email non valido')
  }

  if (data.tax_datum_attributes.email_contact && !isEmail(data.tax_datum_attributes?.email_contact)) {
    errors['tax_datum_attributes.email_contact'] = errors['tax_datum_attributes.email_contact']
      ? errors['tax_datum_attributes.email_contact']
      : t('user.user_form.errors.invalid_email', 'Formato email non valido')
  }

  if (data.role_id === '') {
    errors.role_id = errors.role_id ? errors.role_id : t('user.user_form.errors.invalid_role_id', 'Ruolo richiesto')
  }
  if (!isAdmin) {
    if (!/^[0-9]{11}$/.test(data.tax_datum_attributes?.vat_number)) {
      errors['tax_datum_attributes.vat_number'] = errors['tax_datum_attributes.vat_number']
        ? errors['tax_datum_attributes.vat_number']
        : t('user.user_form.errors.invalid_vat_number', 'Partita IVA non valida')
    }
  }

  return errors
}

export default validateFields
