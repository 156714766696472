import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import i18next from 'i18next'

import config from 'config'
import { error } from 'components/system_wide/notification'

const { t } = i18next

export const initialState = {
  services: [],
  module: 'Condominium',
  loading: false,
}

export const getServices = createAsyncThunk('services/getServices', async ({ module = '' }, thunkApi) => {
  try {
    let state = thunkApi.getState().services
    if (module !== state.module || Object.keys(state.services).length === 0) {
      let url = `${config.SERVER_API_URL}/v1/services?`
      url = url + '&include=service_options,prices,suppliers'
      if (module !== '') {
        url = url + `&filter[with_associable_type]=${module}`
      }
      let response = await axios({ url, method: 'GET' })
      let included = response.data.included
      let services = response.data.data.map(u => {
        let suppliers = u.relationships.suppliers.data.map(su => {
          let supplier = included.find(suu => suu.type === 'suppliers' && suu.id === su.id)
          let { name } = supplier.attributes
          return { value: supplier.id, label: name }
        })
        let { description, position, icon_classes, selectable_price, codename } = u.attributes
        let service_options = u.relationships.service_options.data.map(so => {
          let service_option = included.find(sx => sx.type === 'service_options' && sx.id === so.id)
          let { codename, exclusive } = service_option.attributes
          return { value: service_option.id, label: codename, exclusive }
        })
        return {
          value: u.id,
          codename,
          label: `${description}`,
          position,
          icon_classes,
          selectable_price,
          service_options,
          suppliers,
        }
      })
      return { services, module }
    } else {
      let { services, module } = state
      return { services, module }
    }
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    error(t('store.services.get_services_error', 'Impossibile recuperare i servizi'))
    return thunkApi.rejectWithValue()
  }
})

export const servicesSlice = createSlice({
  name: 'services/services',
  initialState,

  reducers: {},
  extraReducers: {
    [getServices.fulfilled]: (state, action) => {
      if (!action?.payload) return null
      let { services, module } = action.payload
      state.services = services
      state.module = module
    },
    [getServices.rejected]: state => {
      state.services = initialState.services
      state.module = initialState.module
    },
  },
})

export default servicesSlice.reducer
